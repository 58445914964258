import { h, FunctionalComponent } from "preact";
import { Data } from "../store/types";
import { FlagEditor } from "./FlagEditor";

export const FlagsEditor: FunctionalComponent<{
  map: Data["map"];
  markerCoords: google.maps.LatLngLiteral;
}> = ({ map, markerCoords }) => {
  return (
    <div>
      {map.flags.keys
        .map((flagKey) => map.flags.keyed[flagKey]!)
        .map((flag) => (
          <FlagEditor key={flag.key} flag={flag} />
        ))}
      <FlagEditor key="empty" markerCoords={markerCoords} />
    </div>
  );
};
