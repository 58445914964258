import { Fragment, FunctionalComponent, h } from "preact";

export const Rank: FunctionalComponent<{ rank: number }> = ({ rank }) => {
  return (
    <Fragment>
      {rank === 0 && <span title="Eerste plek">🥇</span>}
      {rank === 1 && <span title="Tweede plek">🥈</span>}
      {rank === 2 && <span title="Derde plek">🥉</span>}
    </Fragment>
  );
};
