import { h, FunctionalComponent } from "preact";
import { Data } from "../store/types";
import { TeamEditor } from "./TeamEditor";

export const TeamsEditor: FunctionalComponent<{ map: Data["map"] }> = ({ map }) => {
  return (
    <div>
      {map.teams.keys
        .map((teamKey) => map.teams.keyed[teamKey]!)
        .map((team) => (
          <TeamEditor key={team.key} map={map} team={team} />
        ))}
      <TeamEditor key="empty" map={map} />
    </div>
  );
};
