import { FunctionalComponent, h, Fragment } from "preact";
import { useStore } from "./store/store";
import { Data } from "./store/types";
import { Redirect } from "wouter-preact";
import { useParticipantDetails } from "./store/participantDetails";
import { SkillRender } from "./Utilities";
import { Title } from "./Title";
import { ParticipantHeader } from "./ParticipantHeader";
import { Badge } from "./BadgeBar";

const ParticipantProgressImpl: FunctionalComponent<{
  participant: string;
  data: Data;
}> = ({ participant, data }) => {
  const details = useParticipantDetails(participant);

  if (!details) {
    return <div>Loading</div>;
  }

  const p = details.participant;

  return (
    <div>
      <Title>Details van {p.fullName}</Title>
      <ParticipantHeader participant={p} levels={data.levels} />
      <ul class="sc-progress">
        {data.levels.byRank.map((level) => {
          var skills = data.skills.byRank[level.rank];
          var skillsFinished = skills.filter(
            (skill) => data.progress.byParticipantThenSkill[participant][skill.key].done
          );
          return (
            <Fragment>
              {level.rank != 0 && (
                <li class="sc-progress-skills">
                  <ul>
                    {data.categories.keys.map((cKey) => {
                      const skills = details.byCategory[cKey].byLevel[level.rank].skills;
                      return (
                        !!skills.length && (
                          <li class="mb-1">
                            {data.categories.keyed[cKey].name}
                            <ul>
                              {skills.map((skill) => (
                                <li>
                                  <SkillRender participant={p} skill={skill.skill} progress={skill.progressRank} />
                                </li>
                              ))}
                            </ul>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </li>
              )}
              <li class="sc-progress-skill-name">
                <h3 id={`level-${level.key}`}>
                  <Badge level={level} emphasize={level.rank == details.attainedLevel.rank} />
                  {skills.length > 0 && (
                    <small class="ms-1 text-muted">
                      {skillsFinished.length}/{skills.length} gedaan
                    </small>
                  )}
                </h3>
              </li>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export const ParticipantProgress: FunctionalComponent<{ participant: string }> = ({ participant }) => {
  var data = useStore((s) => s.data);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  if (!(participant in data.participants.keyed)) {
    return <Redirect href="/" />;
  }
  return <ParticipantProgressImpl participant={participant} data={data} />;
};
