import { h, FunctionalComponent } from "preact";
import { FlagCapture } from "../../shared";
import { Data } from "../store/types";
import { useCallback, useEffect, useState } from "preact/hooks";
import { getEventer } from "../singleton";
import { v4 } from "uuid";
import { toDatetimeLocal } from "./utils";
import { Icon } from "./Icon";

export const CaptureEditor: FunctionalComponent<{
  map: Data["map"];
  capture?: FlagCapture;
}> = ({ map, capture }) => {
  const [teamKey, setTeamKey] = useState(capture?.teamKey ?? "");
  const [flagKey, setFlagKey] = useState(capture?.flagKey ?? "");
  const [when, setWhen] = useState(capture?.when ?? "");

  const updateFlagKey = useCallback((event: Event) => {
    setFlagKey((event.target as HTMLSelectElement).value);
  }, []);
  const updateTeamKey = useCallback((event: Event) => {
    setTeamKey((event.target as HTMLSelectElement).value);
  }, []);
  const updateWhen = useCallback((event: Event) => {
    setWhen(new Date((event.target as HTMLInputElement).value).toISOString());
  }, []);

  useEffect(() => {
    setTeamKey(capture?.teamKey ?? "");
    setFlagKey(capture?.flagKey ?? "");
    setWhen(capture?.when ?? "");
  }, [capture]);

  const reset = useCallback(() => {
    setTeamKey(capture?.teamKey ?? "");
    setFlagKey(capture?.flagKey ?? "");
    setWhen(capture?.when ?? "");
  }, [capture]);

  const remove = useCallback(() => {
    if (!capture) {
      throw "Invalid state";
    }
    getEventer(true)({
      type: "flag-capture-remove",
      captureKey: capture.key!,
    });
  }, [capture]);

  const save = useCallback(() => {
    if (!flagKey || !teamKey || !when) {
      throw "Invalid state";
    }

    getEventer(true)({
      type: "flag-capture-upsert",
      capture: {
        key: capture?.key ?? v4(),
        flagKey,
        teamKey,
        when,
      },
    });

    if (!capture) {
      setTeamKey("");
      setFlagKey("");
      setWhen("");
    }
  }, [capture, flagKey, teamKey, when]);

  const saveNow = useCallback(() => {
    if (!flagKey || !teamKey || capture) {
      throw "Invalid state";
    }

    const when = new Date().toISOString();

    getEventer(true)({
      type: "flag-capture-upsert",
      capture: {
        key: v4(),
        flagKey,
        teamKey,
        when,
      },
    });

    setTeamKey("");
    setFlagKey("");
    setWhen("");
  }, [capture, teamKey, flagKey]);

  return (
    <div class="editor-row">
      <select value={flagKey} onChange={updateFlagKey}>
        {!flagKey && <option />}
        {map.flags.keys
          .map((flagKey) => map.flags.keyed[flagKey]!)
          .map((flag) => (
            <option value={flag.key}>{flag.name}</option>
          ))}
      </select>
      <select value={teamKey} onChange={updateTeamKey}>
        {!teamKey && <option />}
        {map.teams.keys
          .map((teamKey) => map.teams.keyed[teamKey]!)
          .map((team) => (
            <option value={team.key}>{team.name}</option>
          ))}
      </select>
      <input type="datetime-local" value={when ? toDatetimeLocal(new Date(when)) : ""} onChange={updateWhen} />
      <button
        title={capture ? "Save" : "Add"}
        onClick={save}
        disabled={
          !teamKey ||
          !flagKey ||
          !when ||
          (teamKey === capture?.teamKey && flagKey === capture?.flagKey && when === capture.when)
        }
      >
        {capture ? <Icon icon="save" /> : <Icon icon="plus-square" />}
      </button>
      {!capture && (
        <button title="Save with current time" disabled={!teamKey || !flagKey} onClick={saveNow}>
          <Icon icon="clock" />
        </button>
      )}
      {capture && (
        <button title="Remove" onClick={remove} disabled={!capture}>
          <Icon icon="x-square" />
        </button>
      )}
      <button title="Reset" onClick={reset}>
        <Icon icon="slash-square" />
      </button>
    </div>
  );
};
