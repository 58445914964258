import { h, FunctionalComponent } from "preact";
import { useStore } from "../store/store";
import { useMemo } from "preact/hooks";
import { MarkerInfoImpl } from "./MarkerInfoImpl";
import { last } from "./utils";

export const MarkerInfo: FunctionalComponent<{ flagKey: string }> = ({ flagKey }) => {
  const map = useStore((s) => s.data?.map);
  const flag = map?.flags.keyed[flagKey];
  const [team, capture] = useMemo(() => {
    const captureKey = last(map?.captures.byFlag[flagKey]);
    const capture = !captureKey ? undefined : map?.captures.keyed[captureKey];
    const teamKey = capture?.teamKey;
    const team = !teamKey ? undefined : map?.teams.keyed[teamKey];
    return [team, capture];
  }, [map?.captures.byFlag[flagKey], map?.captures.keyed, map?.teams.keyed]);
  return map && flag ? <MarkerInfoImpl teams={map.teams} flag={flag} team={team} capture={capture} /> : <div />;
};
