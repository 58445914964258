import { FunctionalComponent, h, Fragment } from "preact";
import { useStore } from "./store/store";
import { Data } from "./store/types";
import { Redirect } from "wouter-preact";
import { useParticipantDetails } from "./store/participantDetails";
import { SkillRender, DetailedProgressRender } from "./Utilities";
import { join } from "./utils";
import { Title } from "./Title";
import { ParticipantHeader } from "./ParticipantHeader";
import { ParticipantDetails, CategoryDetails } from "./store/transformations";

const ParticipantSkillsImpl: FunctionalComponent<{
  participant: string;
  data: Data;
}> = ({ participant, data }) => {
  const details = useParticipantDetails(participant);

  if (!details) {
    return <div>Loading</div>;
  }

  const p = details.participant;

  return (
    <div>
      <Title>Details van {p.fullName}</Title>
      <ParticipantHeader participant={p} levels={data.levels} />
      <div>
        {data.categories.keys.map((c) => (
          <div>
            <Category participant={details} category={details.byCategory[c]} data={data} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Category: FunctionalComponent<{
  participant: ParticipantDetails;
  category: CategoryDetails;
  data: Data;
}> = ({ participant, category, data }) => {
  const guides = data.guides.byCategory[category.category.key];
  return (
    <Fragment>
      <h3>
        {category.category.name} <small class="badge bg-secondary">{category.attainedLevel.name}</small>
      </h3>
      {guides && guides.length && (
        <p class="text-muted mb-1">
          Controle door{" "}
          {join<any>(
            ", ",
            " of ",
            guides.map(({ guide }) => data.participants.keyed[guide].firstName)
          )}
        </p>
      )}
      {category.byLevel
        .filter((l) => l.skills.length)
        .map((level) => (
          <Fragment>
            <p class="lead">
              <DetailedProgressRender
                todo={level.byProgress.todo.length}
                doing={level.byProgress.doing.length}
                done={level.byProgress.done.length}
              >
                {level.level.name}
              </DetailedProgressRender>
            </p>
            <ul>
              {level.skills.map((skill) => (
                <li>
                  <SkillRender
                    participant={participant.participant}
                    skill={skill.skill}
                    progress={skill.progressRank}
                  />
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
    </Fragment>
  );
};

export const ParticipantSkills: FunctionalComponent<{ participant: string }> = ({ participant }) => {
  var data = useStore((s) => s.data);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  if (!(participant in data.participants.keyed)) {
    return <Redirect href="/" />;
  }
  return <ParticipantSkillsImpl participant={participant} data={data} />;
};
