export function createSubscribers<T extends (...args: any) => void>(): Subscribable<T> {
  const subscribers: T[] = [];

  return {
    addSubscriber(subscriber: T) {
      subscribers.push(subscriber);
      return () => {
        var removed = false;
        if (removed) return;
        subscribers.splice(subscribers.indexOf(subscriber), 1);
        removed = true;
      };
    },
    invoke(...params: Parameters<T>) {
      subscribers.forEach((s) => s(...params));
    },
  };
}
export interface Subscribable<T extends (...args: any) => void> {
  addSubscriber(subscriber: T): () => void;
  invoke(...params: Parameters<T>): void;
}
