import { h, FunctionalComponent } from "preact";
import { useStore } from "../store/store";
import { Editor } from "./Editor";

export const TopPanel: FunctionalComponent<{
  markerCoords: google.maps.LatLngLiteral;
}> = ({ markerCoords }) => {
  const canWrite = useStore((s) => s.session.claims.permissions.write);
  if (!canWrite) {
    return <div />;
  }
  return <Editor markerCoords={markerCoords} />;
};
