export const icons = {
  // https://www.flaticon.com/free-icon/question_391038?term=question&page=1&position=22
  unknown:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxM" +
    "nB4IiBoZWlnaHQ9IjUxMnB4Ij4KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRDc4MjsiIGQ9Ik01MTIsMjU2LjAwMUM1MTIsMTE0LjYxNSwzOTcuMzg1LDAsMjU1Ljk5OSwwU" +
    "zAsMTE0LjYxNSwwLDI1Ni4wMDEgIEMwLDM5Ny4zODUsMTE0LjYxNSw1MTIsMjU1Ljk5OSw1MTJTNTEyLDM5Ny4zODUsNTEyLDI1Ni4wMDF6Ii8+CjxwYXRoIHN0eWxlP" +
    "SJmaWxsOiM1MTUyNjI7IiBkPSJNMjg1LjQ2NCwyODQuOTQ4djMwLjIwOWMwLDEzLjAxMi05Ljc1OSwyMi43NzMtMjIuNzczLDIyLjc3M2MtMTIuMDg0LDAtMjIuNzczL" +
    "TkuNzU5LTIyLjc3My0yMi43NzMgIHYtNDguMzMzYzAtMC40NjUsMC40NjUtMC40NjUsMC40NjUtMC45M2MtMC40NjUtMC40NjUtMC40NjUtMC40NjUtMC40NjUtMC45M" +
    "2MwLTEyLjA4NCwxMC42OS0yMi43NzMsMjIuNzczLTIyLjc3MyAgYzI5LjI3OSwwLDUyLjk4MS0yMy4yMzgsNTIuOTgxLTUyLjA1MWMwLTI5LjI3OS0yMy43MDItNTIuM" +
    "DUxLTUyLjk4MS01Mi4wNTFjLTE5LjA1NSwwLTM1Ljc4NiwxMC4yMjQtNDQuNjE1LDI1LjU2MSAgYy02LjUwNiwxMS4xNTQtMjAuNDQ5LDE0Ljg3Mi0zMS42MDMsOC4zN" +
    "jZjLTEwLjY5LTYuNTA2LTE0LjQwNy0xOS45ODQtNy45MDEtMzEuMTM4YzE2LjczLTI4LjgxNCw0OC4zMzMtNDguMzMzLDg0LjExOS00OC4zMzMgIGM1My45MSwwLDk4L" +
    "jA2MSw0My42ODYsOTguMDYxLDk3LjU5N0MzNjAuNzUyLDIzNi4xNDksMzI4LjY4NSwyNzQuNzIzLDI4NS40NjQsMjg0Ljk0OHogTTI4NS40NjQsMzg2LjcyN3Y4LjM2N" +
    "iAgYzAsMTMuMDEyLTkuNzU5LDIyLjc3My0yMi43NzMsMjIuNzczYy0xMi4wODQsMC0yMi43NzMtOS43NTktMjIuNzczLTIyLjc3M3YtOC4zNjZjMC0xMi41NDksMTAuN" +
    "jktMjIuNzczLDIyLjc3My0yMi43NzMgIEMyNzUuNzA0LDM2My45NTQsMjg1LjQ2NCwzNzQuMTc5LDI4NS40NjQsMzg2LjcyN3oiLz4KPHBhdGggc3R5bGU9Im9wYWNpd" +
    "Hk6MC4xO2VuYWJsZS1iYWNrZ3JvdW5kOm5ldyAgICA7IiBkPSJNNjguODcyLDI1Ni4wMDFjMC0xMjkuNzA2LDk2LjQ2Ni0yMzYuODY2LDIyMS41NjQtMjUzLjY4OCAgQ" +
    "zI3OS4xNzIsMC43OTgsMjY3LjY4LDAsMjU2LjAwMSwwQzExNC42MTUsMCwwLDExNC42MTUsMCwyNTYuMDAxUzExNC42MTUsNTEyLDI1Ni4wMDEsNTEyYzExLjY4LDAsM" +
    "jMuMTcxLTAuNzk4LDM0LjQzNi0yLjMxMyAgQzE2NS4zMzgsNDkyLjg2NSw2OC44NzIsMzg1LjcwNSw2OC44NzIsMjU2LjAwMXoiLz4KPGc+CjwvZz4KPGc+CjwvZz4KP" +
    "Gc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZ" +
    "z4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==",
};
