import Color from "color";

export function toDatetimeLocal(date: Date) {
  var ten = function (i: number) {
    return (i < 10 ? "0" : "") + i;
  };
  var YYYY = date.getFullYear();
  var MM = ten(date.getMonth() + 1);
  var DD = ten(date.getDate());
  var HH = ten(date.getHours());
  var II = ten(date.getMinutes());
  var SS = ten(date.getSeconds());

  return YYYY + "-" + MM + "-" + DD + "T" + HH + ":" + II + ":" + SS;
}

export function last<T>(array: T[] | undefined): T | undefined {
  return Array.isArray(array) ? array[array.length - 1] : undefined;
}

export function safeColor(color: string) {
  try {
    return Color(color);
  } catch {
    return Color("black");
  }
}
