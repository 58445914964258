import { FunctionComponent, h, Fragment, JSX, toChildArray } from "preact";

import { Link } from "wouter-preact";
import { Skill, Participant, Patrol, Dictionary } from "./store/types";
import { ProgressRank } from "./store/transformations";
import * as i18n from "./i18n";
import { useMemo } from "preact/hooks";

import { Badge } from "./BadgeBar";
import { useStore as useStoreLib } from "./storelib";
import { useStore } from "./store/store";
import { participantDetailsStore } from "./store/store";
import { icons } from "./Icons";

const ProgressRenders: Record<ProgressRank, FunctionComponent> = {
  [ProgressRank.todo]: ({ children }) => <Fragment>{children}</Fragment>,
  [ProgressRank.doing]: ({ children }) => (
    <Fragment>
      {children} <em>(bezig)</em>
    </Fragment>
  ),
  [ProgressRank.done]: ({ children }) => (
    <Fragment>
      <s class="text-muted">{children}</s> <em>(gehaald)</em>
    </Fragment>
  ),
};

export const ProgressRender: FunctionComponent<{ progress: ProgressRank }> = ({ progress, children }) => {
  const Render = ProgressRenders[progress];
  return <Render>{children}</Render>;
};

export const DetailedProgressRender: FunctionComponent<{
  todo?: number;
  doing?: number;
  done?: number;
}> = ({ todo, doing, done, children }) => {
  if (!doing && !done) {
    return (
      <Fragment>
        {children} ({todo} te gaan)
      </Fragment>
    );
  }
  if (!doing) {
    return (
      <Fragment>
        {children} ({done} gedaan en {todo} te gaan)
      </Fragment>
    );
  }
  if (!done) {
    return (
      <Fragment>
        {children} ({doing} bezig en {todo} te gaan)
      </Fragment>
    );
  }
  return (
    <Fragment>
      {children} ({done} gedaan, {doing} bezig en {todo} te gaan)
    </Fragment>
  );
};

export const SkillRender: FunctionComponent<
  {
    skill: Skill;
    participant: Participant;
    progress: ProgressRank;
  } & JSX.HTMLAttributes
> = ({ skill, participant, progress, ...rest }) => {
  const Render = ProgressRenders[progress];
  return (
    <Link href={`/${i18n.participant}/${participant.key}/${i18n.skill}/${skill.key}`}>
      <a {...rest}>
        <Render>{skill.name}</Render>
      </a>
    </Link>
  );
};

export const SkillLink: FunctionComponent<{ skill: Skill; participant: Participant } & JSX.HTMLAttributes> = ({
  skill,
  participant,
  children,
  ...rest
}) => {
  return (
    <Link {...rest} href={`/${i18n.participant}/${participant.key}/${i18n.skill}/${skill.key}`}>
      {children || skill.name}
    </Link>
  );
};

type NameType = "fullName" | "firstName";
export type ParticipantRenderProps = JSX.HTMLAttributes & {
  participant: Participant;
  nameType?: NameType;
  patrol?: boolean;
  link?: boolean;
  progress?: boolean;
};
export const ParticipantRender: FunctionComponent<ParticipantRenderProps> = ({
  participant,
  nameType,
  patrol,
  children,
  link,
  progress,
  class: c,
  ...rest
}) => {
  const patrols = useStore((s) => s.data && s.data.patrols.byParticipantByYear);
  const date = useDate((d) => {
    return d.getMonth() < 7 ? d.getFullYear() - 1 : d.getFullYear();
  });
  const patrolObject = patrols && patrols[participant.key][date];
  const content = <Fragment>{children || participant[nameType || "firstName"]}</Fragment>;

  const result = link ? (
    <Link href={`/${i18n.participant}/${participant.key}`}>
      <a class={c} {...rest}>
        {content}
      </a>
    </Link>
  ) : (
    <span class={c} {...rest} href={`/${i18n.participant}/${participant.key}`}>
      {content}
    </span>
  );

  return (
    <Fragment>
      {patrol && <PatrolTag link patrol={patrolObject} />}
      {result}
      {progress && (
        <span class="ms-1">
          <ParticipantProgressRender participant={participant} />
        </span>
      )}
    </Fragment>
  );
};

export const ParticipantProgressRender: FunctionComponent<{
  participant: Participant;
}> = ({ participant }) => {
  const details = useStoreLib(participantDetailsStore, (s) => s && s[participant.key]);
  return <Fragment>{details && <Badge level={details.attainedLevel} emphasize />}</Fragment>;
};

enum LogoType {
  valid,
  notFound,
  noPatrol,
}
export const PatrolTag: FunctionComponent<{
  patrol: Patrol | undefined;
  link?: boolean;
}> = ({ patrol, link }) => {
  const title = patrol ? patrol.name : undefined;
  const hasIcon = !!patrol?.icon;
  const logo = hasIcon ? patrol!.key : "unknown";
  const color = patrol ? patrol.key : "unknown";
  const clazz = `patrol-tag patrol-color-${color} patrol-logo-${logo} patrol-${patrol ? "" : "not-"}exists patrol-has-${
    hasIcon ? "" : "no-"
  }logo`;

  const tag = (
    <span title={title} class={clazz}>
      <svg class="patrol-squares">
        <use xlinkHref="#squares"></use>
      </svg>
      <img class={`patrol-logo`} src={patrol?.icon || icons.unknown}></img>
    </span>
  );
  return !link || !patrol ? (
    tag
  ) : (
    <Link href={`/${i18n.patrol}/${patrol.key}/`}>
      <a>{tag}</a>
    </Link>
  );
};

export const SecondaryButton: FunctionComponent<{
  href: string;
  focus: boolean;
  mx?: number;
}> = ({ href, focus, mx = 2, children }) => {
  return (
    <Link
      href={href}
      class={`text-nowrap ${mx > 0 ? `mx-${mx}` : ""} btn btn-sm btn-${focus ? "secondary" : "outline-secondary"}`}
    >
      {toChildArray(children)}
    </Link>
  );
};

function get<TValue>(dict: Dictionary<string, TValue>, key: string) {
  return key in dict ? dict[key] : undefined;
}

export function useDate<T>(transformer: (date: Date) => T) {
  const date = new Date();
  return useMemo(() => transformer(date), [date]);
}
