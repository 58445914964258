import { FunctionalComponent, h, Fragment, JSX, FunctionComponent } from "preact";
import { useActions, actions, useStore, store, State } from "./store/store";
import { useState, useEffect, useRef, useCallback } from "preact/hooks";
import { Link, useRoute } from "wouter-preact";
import { Container } from "./Bootstrap";
import * as i18n from "./i18n";

import { RoutingParticipantsSelector } from "./ParticipantsSelector";
import { getApi } from "./singleton";
import SVG from "react-inlinesvg";
import produce from "immer";
import { login, logout } from "./store/session";
import { Token as WireToken } from "../shared/wired";
import { Skill } from "./store/types";
import { SecondaryButton } from "./Utilities";
const PSVG: FunctionalComponent<{ src: string } & JSX.SVGAttributes> = SVG as any;

export const Password: FunctionalComponent<{
  cancel?: () => void;
  cancelText?: string;
}> = ({ cancel, cancelText }) => {
  const [password, setPassword] = useState("");
  const [checking, setChecking] = useState(false);

  const canSubmit = !!password && !checking;
  const stayLoggedIn = useStore((s) => s.stayLoggedIn);

  const { openAlert } = useActions(actions);

  const passwordFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    passwordFieldRef.current!.focus();
  });
  function setStayLoggedIn(stayLoggedIn: boolean) {
    store.update(
      produce((state: State) => {
        state.stayLoggedIn = stayLoggedIn;
      })
    );
  }

  const validate = useCallback(
    async function validate(e: Event) {
      e.preventDefault();
      try {
        setChecking(true);
        const api = getApi()!;
        const response = await api.login(password);
        if (response.code == 200) {
          const pseudoToken: WireToken = response.body;
          login(
            {
              token: pseudoToken.token,
              expiration: new Date(pseudoToken.expiration),
            },
            pseudoToken.claims
          );

          history.replaceState(history.state, document.title);
        } else if (response.code == 401) {
          switch (response.body.detail) {
            case "password required":
              openAlert({
                title: "Voer je wachtwoord in",
                severity: "warning",
              });
              break;
            case "invalid password":
              openAlert({
                title: "Het wachtwoord is fout",
                text: "Voer een goed wachtwoord in",
                severity: "danger",
              });
              break;
          }
          console.log(response.body);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setChecking(false);
      }
    },
    [setChecking, stayLoggedIn, password]
  );

  return (
    <form>
      <div class="d-flex flex-row my-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <div
              class="input-group-text input-group-text-sm"
              style="-webkit-filter: grayscale(100%); filter: grayscale(100%);"
            >
              🔐
            </div>
          </div>
          <input
            type="password"
            class="form-control"
            id="password"
            name="password"
            aria-describedby="password-help"
            value={password}
            onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
            placeholder="Wachtwoord"
            ref={passwordFieldRef}
            autocomplete="password"
          />
        </div>
      </div>
      <div class="d-flex flex-row my-2">
        <input value="Inloggen" class="btn btn-primary btn-sm" type="submit" onClick={validate} disabled={!canSubmit} />
        {cancel && (
          <input
            value={cancelText ?? "Annuleren"}
            class="ms-2 btn btn-secondary btn-sm"
            type="button"
            onClick={cancel}
            disabled={checking}
          />
        )}
        <div class="ms-2 form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            id="stayLoggedIn"
            checked={stayLoggedIn}
            onChange={() => setStayLoggedIn(!stayLoggedIn)}
          />
          <label class="form-check-label text-nowrap" for="stayLoggedIn">
            Blijf ingelogd
          </label>
        </div>
      </div>
    </form>
  );
};

export const Menu: FunctionalComponent<{ openPassword: () => void }> = ({ openPassword }) => {
  const [scoutsMatch, scoutParams] = useRoute(`/${i18n.participant}/:name/:section?/:skill?/:rest*`);
  const [skillMatch, skillParams] = useRoute(`/${i18n.skill}/:skill/:rest*`);
  const [groupMatch] = useRoute(`/${i18n.group}/`);
  const [patrolMatch] = useRoute(`/${i18n.patrol}/`);
  const participant = scoutsMatch && scoutParams!.name;
  const participantSkill = scoutsMatch && scoutParams!.skill;
  const participantSection = scoutsMatch && scoutParams!.section;
  const skill = skillParams && skillParams.skill;

  const [useVlaggenroof] = useRoute("/vlaggenroof");

  const skills = useStore((s) => s.data && s.data.skills);

  const session = useStore((s) => s.session);
  const hasToken = !!session.token;

  const skillObject = skills && participantSkill && skills.keyed[participantSkill];

  const connected = useStore((s) => s.connected);

  const tab = skillObject
    ? "skill"
    : participantSection == i18n.edit
    ? "edit"
    : participantSection == i18n.skill
    ? "details"
    : participantSection == i18n.progress
    ? "progress"
    : participant
    ? "overview"
    : groupMatch
    ? "groupOverview"
    : patrolMatch
    ? "patrolOverview"
    : skillMatch
    ? "skillOverview"
    : useVlaggenroof
    ? "vlaggenroof"
    : "home";

  const logoStyle: JSX.CSSProperties = {
    height: "21px",
    width: "21px",
    backgroundImage: `url('/${useStore((s) => s["menu-logo"])}')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const flagHuntEnabled = useStore((s) => s.features.flagHunt);

  return (
    <Fragment>
      <div class="d-flex flex-row my-2">
        {connected ? (
          <Fragment>
            <RoutingParticipantsSelector class="form-select-sm flex-grow-1 nav-participant-selector" />
            {hasToken ? (
              <span
                onClick={logout}
                class="ms-2 btn btn-sm  btn-light text-nowrap"
                style="-webkit-filter: grayscale(100%); filter: grayscale(100%);"
              >
                Log uit 🔓
              </span>
            ) : (
              <span
                onClick={openPassword}
                class="ms-2 btn btn-sm btn-light text-nowrap"
                style="-webkit-filter: grayscale(100%); filter: grayscale(100%);"
              >
                Log in 🔐
              </span>
            )}
          </Fragment>
        ) : (
          <span
            class="ms-2 btn btn-sm btn-light text-nowrap"
            style="-webkit-filter: grayscale(100%); filter: grayscale(100%);"
          >
            Niet verbonden! Bezig opnieuw te verbinden...
          </span>
        )}
      </div>
      {flagHuntEnabled !== "force" && (
        <div class="d-flex flex-row flex-nowrap mt-2 mb-0 pb-2 mx-n2" style={{ overflow: "auto" }}>
          <Link href={`/`}>
            <a class={`mx-2 px-1 btn btn-sm btn-${tab == "home" ? "secondary" : "outline-secondary"}`}>
              <div style={logoStyle}></div>
            </a>
          </Link>
          {participant ? (
            <ParticipantView
              participant={participant}
              tab={tab}
              skillObject={skillObject || undefined}
              participantSkill={participantSkill}
            />
          ) : skill ? (
            <SkillView skill={skill} tab={tab} />
          ) : (
            <GenericView tab={tab} />
          )}
        </div>
      )}
    </Fragment>
  );
};

enum Mode {
  showMenu,
  showPassword,
}
export const Nav: FunctionalComponent = () => {
  const [mode, setMode] = useState(Mode.showMenu);
  const token = useStore((s) => s.session.token);
  useEffect(() => {
    if (token) {
      setMode(Mode.showMenu);
    }
  }, [token]);
  return (
    <Container>
      {mode == Mode.showMenu ? (
        <Menu openPassword={() => setMode(Mode.showPassword)} />
      ) : (
        <Password cancel={() => setMode(Mode.showMenu)} cancelText={"Terug"} />
      )}
    </Container>
  );
};

const GenericView: FunctionComponent<{ tab: string }> = ({ tab }) => {
  const showPatrols = useStore((s) => s.features.patrols);
  const flagHuntEnabled = useStore((s) => s.features.flagHunt);
  return (
    <Fragment>
      <SecondaryButton href={`/${i18n.group}/`} focus={tab == "groupOverview"}>
        Voortgang groep
      </SecondaryButton>
      {showPatrols && (
        <SecondaryButton href={`/${i18n.patrol}/`} focus={tab == "patrolOverview"}>
          Patrouilles
        </SecondaryButton>
      )}
      {flagHuntEnabled && (
        <SecondaryButton href={`/vlaggenroof`} focus={tab == "vlaggenroof"}>
          Vlaggenroof
        </SecondaryButton>
      )}
    </Fragment>
  );
};

const SkillView: FunctionComponent<{ skill: string; tab: string }> = ({ skill, tab }) => {
  return (
    <Fragment>
      <SecondaryButton href={`/${i18n.skill}/${skill}`} focus={tab == "skillOverview"}>
        Overzicht
      </SecondaryButton>
    </Fragment>
  );
};

const ParticipantView: FunctionComponent<{
  participant: string;
  tab: string;
  skillObject: Skill | undefined;
  participantSkill: string | boolean;
}> = ({ participant, tab, skillObject, participantSkill }) => {
  var hasWriteAccess = useStore((s) => s.session.claims.permissions.write);
  return (
    <Fragment>
      <SecondaryButton href={`/${i18n.participant}/${participant}`} focus={tab == "overview"}>
        Overzicht
      </SecondaryButton>
      <SecondaryButton href={`/${i18n.participant}/${participant}/${i18n.progress}`} focus={tab == "progress"}>
        Voortgang
      </SecondaryButton>
      <div class="btn-group mx-2" role="group">
        <SecondaryButton href={`/${i18n.participant}/${participant}/skills`} mx={0} focus={tab == "details"}>
          Details
        </SecondaryButton>
        {skillObject && (
          <Fragment>
            <SecondaryButton
              href={`/${i18n.participant}/${participant}/skills/${participantSkill}`}
              mx={0}
              focus={tab == "skill"}
            >
              {skillObject.name}
            </SecondaryButton>
          </Fragment>
        )}
      </div>
      {hasWriteAccess && (
        <SecondaryButton href={`/${i18n.participant}/${participant}/${i18n.edit}`} focus={tab == "edit"}>
          Bewerken
        </SecondaryButton>
      )}
    </Fragment>
  );
};
