import { createSubscribers, Subscribable } from "../../shared/subscribers";

interface Collection<TValue> {
  add(...values: TValue[]): void;
  update(...updates: { before: TValue; after: TValue }[]): void;
  remove(...values: TValue[]): void;
  set(...values: TValue[]): void;
  addSubscriber: Subscribable<(action: Action<TValue>) => void>["addSubscriber"];
}

type Action<TValue> =
  | {
      type: "add";
      values: TValue[];
    }
  | {
      type: "remove";
      values: TValue[];
    }
  | {
      type: "update";
      updates: { before: TValue; after: TValue }[];
    }
  | {
      type: "set";
      values: TValue[];
    };

export function createIndexed<TValue>() {}

export function createCollection<TValue>(): Collection<TValue> {
  var subscribers = createSubscribers<(action: Action<TValue>) => void>();
  return {
    add(...values) {
      subscribers.invoke({ type: "add", values });
    },
    remove(...values) {
      subscribers.invoke({ type: "remove", values });
    },
    update(...values) {
      subscribers.invoke({ type: "update", updates: values });
    },
    set(...values) {
      subscribers.invoke({ type: "set", values });
    },
    addSubscriber: subscribers.addSubscriber,
  };
}

export function createIndex<TValue>(collection: Collection<TValue>, selectKey: (value: TValue) => string) {
  var values: Record<string, TValue> = {};
  collection.addSubscriber((action) => {
    switch (action.type) {
      case "add":
        var added: typeof values = {};
        action.values.forEach((value) => {
          added[selectKey(value)] = value;
        });
        values = { ...values, ...added };
        break;
      case "remove":
        var valuesWithout = { ...values };
        action.values.forEach((value) => {
          delete valuesWithout[selectKey(value)];
        });
        values = valuesWithout;
        break;
      case "update":
        var valuesWithout = { ...values };
        var updates = action.updates.map(({ before, after }) => ({
          before,
          after,
          keyBefore: selectKey(before),
          keyAfter: selectKey(after),
        }));
        updates.forEach(({ keyBefore, keyAfter }) => {
          if (keyBefore != keyAfter) {
            delete valuesWithout[keyBefore];
          }
        });
        updates.forEach(({ after, keyAfter }) => (valuesWithout[keyAfter] = after));
        values = valuesWithout;
        break;
      case "set":
        var newValues: typeof values = {};
        action.values.forEach((value) => (newValues[selectKey(value)] = value));
        values = newValues;
    }
  });
  return {
    get values() {
      return values;
    },
  };
}

var collection = createCollection<{ key: string; desc: string }>();
var indexedCollection = createIndex(collection, (i) => i.key);
collection.add({ key: "hey", desc: "1" });
collection.add({ key: "bar", desc: "2" });
console.log(collection, indexedCollection);
