import { FunctionalComponent, h, Fragment } from "preact";
import { useStore } from "./store/store";
import { ParticipantProgress } from "./ParticipantProgress";
import { Title } from "./Title";

export const ParticipantProgressMulti: FunctionalComponent<{}> = ({}) => {
  var data = useStore((s) => s.data);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      {data?.participants.scoutKeys.map((s) => (
        <div style={{ pageBreakAfter: "always" }}>
          <ParticipantProgress participant={s} />
        </div>
      ))}
      <Title>Details van iedereen</Title>
    </Fragment>
  );
};
