import { LoginResponse, LoginRequest } from "../shared";

export function createApi(host: string = location.origin) {
  return {
    async login(
      password: string
    ): Promise<{ code: 200; body: LoginResponse } | { code: 401 | 400; body: { detail: string } }> {
      const request = await fetch(`${host}/api/token`, {
        method: "POST",
        body: JSON.stringify(<LoginRequest>{ password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (request.status != 200 && request.status != 400 && request.status != 401) {
        throw "Unexpected response";
      }
      return { code: request.status, body: await request.json() };
    },
  };
}
