import { FunctionalComponent, h, Fragment } from "preact";
import { useStore } from "./store/store";
import { Data, Participant } from "./store/types";
import { useParticipantOverview } from "./store/participantOverview";
import { CategoryOverview } from "./store/transformations";
import { Redirect } from "wouter-preact";
import { SkillRender } from "./Utilities";
import { Title } from "./Title";
import { ParticipantHeader } from "./ParticipantHeader";

const ParticipantOverviewImpl: FunctionalComponent<{
  participant: string;
  data: Data;
}> = ({ participant, data }) => {
  const overview = useParticipantOverview(participant);

  if (!overview) {
    return <div>Loading</div>;
  }

  const p = overview.participant;

  return (
    <div>
      <Title>Overzicht van {p.fullName}</Title>
      <ParticipantHeader participant={p} levels={data.levels} />
      <div>
        {overview!.categories.map((c) => (
          <div>
            <Category participant={p} category={c} data={data} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Category: FunctionalComponent<{
  participant: Participant;
  category: CategoryOverview;
  data: Data;
}> = ({ participant, category }) => {
  const nextLevel = category.nextLevel;
  const nextAvailable = nextLevel && nextLevel.byProgress.doing.length + nextLevel.byProgress.todo.length > 0;
  return (
    <Fragment>
      <h3>
        {category.category.name} <small class="badge bg-secondary">{category.attainedLevel.name}</small>
      </h3>
      {nextLevel && (
        <Fragment>
          {nextAvailable ? (
            <p class="lead">
              Nog {nextLevel.byProgress.doing.length + nextLevel.byProgress.todo.length} te gaan om{" "}
              <span class="badge bg-light text-dark">{nextLevel.level.name}</span> te bereiken:
            </p>
          ) : (
            <p class="lead">
              Geen volgende niveau, {participant.firstName} is klaar met {category.category.name}
            </p>
          )}
          <ul>
            {nextLevel.byProgress.doing.map((s) => (
              <li>
                <SkillRender skill={s.skill} progress={s.progressRank} participant={participant} />
              </li>
            ))}
            {nextLevel.byProgress.todo.map((s) => (
              <li>
                <SkillRender skill={s.skill} progress={s.progressRank} participant={participant} />
              </li>
            ))}
            {nextLevel.byProgress.done.map((s) => (
              <li>
                <SkillRender skill={s.skill} progress={s.progressRank} participant={participant} />
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </Fragment>
  );
};

export const ParticipantOverview: FunctionalComponent<{ participant: string }> = ({ participant }) => {
  var data = useStore((s) => s.data);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  if (!(participant in data.participants.keyed)) {
    return <Redirect href="/" />;
  }
  return <ParticipantOverviewImpl participant={participant} data={data} />;
};
