import { Loader } from "@googlemaps/js-api-loader";

let acceptFunc: Function;

export async function load(key: string) {
  const loader = new Loader({
    apiKey: key,
    version: "weekly",
  });

  await loader.load();

  acceptFunc();
}

export const loading = new Promise((accept) => (acceptFunc = accept));
