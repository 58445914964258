import { h, FunctionComponent, Fragment } from "preact";
import { Title } from "./Title";
import { useStore, participantDetailsStore } from "./store/store";
import { useStore as useStoreLib } from "./storelib";
import { Data, Participant, Patrol } from "./store/types";
import { useMemo } from "preact/hooks";
import { mapd, groupBy } from "./functional";
import { PatrolTag, ParticipantRender } from "./Utilities";
import { lookup, join, flatten } from "./utils";
import { transformMostRecentPatrolYear, ProgressRank, ParticipantDetails } from "./store/transformations";
import { Badge, starImages } from "./BadgeBar";

const PatrolsOverviewImpl: FunctionComponent<{ data: Data }> = ({ data }) => {
  const showStars = useStore((s) => s.features.stars);
  const display = useMemo(() => {
    const mostRecentYear = transformMostRecentPatrolYear(data.patrols.years);
    const populatedPatrols = mapd(data.patrols.keyed)
      .values<PopulatedPatrol>((patrol) => {
        const usage =
          patrol.years.length == 0
            ? PatrolUsage.unused
            : patrol.years.some((y) => y.year == mostRecentYear)
            ? PatrolUsage.current
            : PatrolUsage.previous;
        const look = lookup(data.participants.keyed);
        const years = patrol.years!.map((y) => ({
          year: y.year,
          pl: y.pl ? look(y.pl) : undefined,
          apl: y.apl ? look(y.apl) : undefined,
          otherMembers: [...y.members.filter((m) => m != y.pl && m != y.apl).map(look)],
          members: y.members.map(look),
        }));

        return usage != PatrolUsage.current
          ? {
              patrol,
              years,
              usage,
            }
          : {
              patrol,
              years,
              usage,
              currentYear: years.find((y) => y.year == mostRecentYear)!,
            };
      })
      .sort((l, r) => l.patrol.name.localeCompare(r.patrol.name));

    const currentPopulatedPatrols = populatedPatrols.filter(
      (p) => p.usage == PatrolUsage.current
    ) as CurrentPopulatedPatrol[];
    const previouslyPopulatedPatrols = populatedPatrols.filter((p) => p.usage == PatrolUsage.previous);
    const unusedPopulatedPatrols = populatedPatrols.filter((p) => p.usage == PatrolUsage.unused);
    return {
      mostRecentYear,
      patrols: populatedPatrols,
      currentPatrols: currentPopulatedPatrols,
      previouslyPatrols: previouslyPopulatedPatrols,
      unusedPatrols: unusedPopulatedPatrols,
    };
  }, [data.patrols, data.participants]);
  return (
    <div>
      <Title>Patrouilles</Title>
      <h1>Patrouilles</h1>
      {!!display.currentPatrols.length &&
        join(
          <hr />,
          <hr />,
          display.currentPatrols.map((p) => (
            <div>
              <CurrentPatrolSection patrol={p} stars={showStars} />
            </div>
          ))
        )}
      {/* {!!display.previouslyPatrols.length && <Fragment>
            <h2>Oude patrouilles</h2>
            {display.previouslyPatrols.map(p => <PatrolSection patrol={p} />)}
        </Fragment>}
        {!!display.unusedPatrols.length && <Fragment>
            <h2>Ongebruikte patrouilles</h2>
            {display.unusedPatrols.map(p => <PatrolSection patrol={p} />)}
        </Fragment>} */}
    </div>
  );
};

const RenderMember: FunctionComponent<{
  prefix?: string;
  details: ParticipantDetails;
  stars?: boolean;
}> = ({ prefix, details, stars }) => {
  return (
    <Fragment>
      <div style={{ display: "block", width: "300px" }}>
        {prefix}
        <ParticipantRender progress link participant={details.participant} />
      </div>
      {stars &&
        flatten(
          Object.values(details.byCategory).map((c) =>
            flatten(c.byLevel.map((l) => l.skills.map((s) => s.progressRank)))
          )
        )
          .filter((p) => p != ProgressRank.todo)
          .sort((a, b) => b - a)
          .map((p) =>
            p == ProgressRank.todo
              ? starImages.emptyYellow
              : p == ProgressRank.done
              ? starImages.fullYellow
              : starImages.halfYellow
          )}
    </Fragment>
  );
};
const CurrentPatrolSection: FunctionComponent<{
  patrol: CurrentPopulatedPatrol;
  stars?: boolean;
}> = ({ patrol, stars }) => {
  const details = useStoreLib(participantDetailsStore, (s) => s && patrol.currentYear.members.map((m) => s[m.key]));
  const levels = details && details.map((p) => p.attainedLevel);
  const gLevels = levels && groupBy(levels, (l) => l.key);
  const tags = gLevels && (
    <Fragment>
      {Object.entries(gLevels)
        .sort((l, r) => l[1][0].rank - r[1][0].rank)
        .map(([, levels]) => (
          <Fragment>
            <Badge prefix={<Fragment>{levels.length} x </Fragment>} level={levels[0]} />
          </Fragment>
        ))}
    </Fragment>
  );
  return (
    <Fragment>
      <h3>
        <PatrolTag patrol={patrol.patrol} />
        {patrol.patrol.name}
      </h3>
      <p>{tags}</p>
      {details && (
        <ul class="list-unstyled">
          {patrol.currentYear.pl && (
            <li>
              <strong>
                <RenderMember
                  prefix={"PL: "}
                  details={details.find((d) => d.participant == patrol.currentYear.pl)!}
                  stars={stars}
                />
              </strong>
            </li>
          )}
          {patrol.currentYear.apl && (
            <li>
              <strong>
                <RenderMember
                  prefix={"APL: "}
                  details={details.find((d) => d.participant == patrol.currentYear.apl)!}
                  stars={stars}
                />
              </strong>
            </li>
          )}
          {patrol.currentYear.otherMembers.map((m) => (
            <li>
              <RenderMember details={details.find((d) => d.participant == m)!} stars={stars} />
            </li>
          ))}
        </ul>
      )}
    </Fragment>
  );
};

export const PatrolsOverview: FunctionComponent = () => {
  const data = useStore((s) => s.data);
  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }

  return <PatrolsOverviewImpl data={data} />;
};

enum PatrolUsage {
  current,
  previous,
  unused,
}

type PopulatedPatrol = CurrentPopulatedPatrol | NonCurrentPopulatedPatrol;
interface CurrentPopulatedPatrol extends PopulatedPatrolBase {
  currentYear: PopulatedPatrolYear;
  usage: PatrolUsage.current;
}
interface NonCurrentPopulatedPatrol extends PopulatedPatrolBase {
  patrol: Patrol;
  usage: PatrolUsage.previous | PatrolUsage.unused;
}
interface PopulatedPatrolBase {
  patrol: Patrol;
  years: PopulatedPatrolYear[];
  usage: PatrolUsage;
}
interface PopulatedPatrolYear {
  year: number;
  pl: Participant | undefined;
  apl: Participant | undefined;
  otherMembers: Participant[];
  members: Participant[];
}
