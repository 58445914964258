import { FunctionalComponent, h, Fragment, FunctionComponent, JSX } from "preact";
import { RoutingParticipantsSelector } from "./ParticipantsSelector";
import { useStore } from "./store/store";
import { useMemo } from "preact/hooks";
import { SkillLink, ParticipantRender } from "./Utilities";
import { Row } from "./Bootstrap";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/nl";
import { Tm } from "./store/i18l";

dayjs.locale("nl");
dayjs.extend(relativeTime);

const limit = 10;

export const Overview: FunctionalComponent = () => {
  return (
    <div class="my-5">
      <h1 class="'display-4">Technieken</h1>
      <p class="lead mb-5">
        <Tm prop="Intro" />
      </p>
      <p>
        Kies hieronder je naam om verder te gaan:
        <RoutingParticipantsSelector />
      </p>
      <Row>
        <div class="col-lg-6">
          <h2>Laatste {limit} wijzigingen</h2>
          <Changes limit={limit} />
        </div>
        <div class="col-lg-6">
          <h2>Top {limit}</h2>
          <Ranking limit={limit} />
        </div>
      </Row>
    </div>
  );
};

const Ranking: FunctionalComponent<{ limit?: number }> = ({ limit }) => {
  const progress = useStore((s) => s.data && s.data.progress);
  const participants = useStore((s) => s.data && s.data.participants);
  const skills = useStore((s) => s.data && s.data.skills);
  const ranked = useMemo(() => {
    if (!participants || !skills || !progress) {
      return [];
    }

    const ranked = participants.scoutKeys.map((k) => {
      const participantSkills = progress.byParticipantThenSkill[k] || {};

      return {
        participant: participants.keyed[k],
        ...Object.values(participantSkills)
          .map(({ doing, done }) => ({
            done: done ? 1 : 0,
            doing: doing ? 1 : 0,
          }))
          .reduce(
            (acc, val) => ({
              done: acc.done + val.done,
              doing: acc.doing + val.doing,
            }),
            { doing: 0, done: 0 }
          ),
      };
    });

    ranked.sort((l, r) => r.done + r.doing / 1000 - (l.done + l.doing / 1000));

    return ranked.slice(0, limit);
  }, [participants, progress]);

  return ranked && skills ? (
    <ol>
      {ranked.map(({ participant, done, doing }) => (
        <li>
          <strong>
            <ParticipantRender link patrol participant={participant} nameType={"firstName"} />
          </strong>{" "}
          heeft {done} technieken gedaan
          {doing - done > 0 && <Fragment>, en is aan {doing - done} begonnen</Fragment>}
        </li>
      ))}
    </ol>
  ) : (
    <ol>
      <li>Geen scouts gevonden</li>
    </ol>
  );
};

const Changes: FunctionComponent<{ limit?: number } & JSX.HTMLAttributes> = ({ limit, ...attr }) => {
  const progress = useStore((s) => s.data && s.data.progress);
  const participants = useStore((s) => s.data && s.data.participants);
  const skills = useStore((s) => s.data && s.data.skills);
  const chrono = useMemo(() => {
    if (!progress) {
      return [];
    }
    const result = [];
    for (const item of progress.chrono) {
      if (result.length > 0) {
        var compItem = result[result.length - 1];
        if (compItem.skill == item.skill && compItem.participant == item.participant) {
          continue;
        }
      }
      result.push(item);
      if (limit != undefined && result.length >= limit) {
        break;
      }
    }
    return result;
  }, [progress]);
  return (
    <div {...attr}>
      {chrono && participants && skills && (
        <ul>
          {chrono.map((p) => (
            <li>
              <strong>
                <ParticipantRender link patrol participant={participants.keyed[p.participant]} nameType={"firstName"} />
              </strong>
              {" heeft "}
              <strong>
                <SkillLink participant={participants.keyed[p.participant]} skill={skills.keyed[p.skill]}>
                  {skills.keyed[p.skill].name}
                </SkillLink>
              </strong>{" "}
              {dayjs(p.when).fromNow()} <strong>{p.progress == "doing" ? "gestart" : "afgerond"}</strong>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
