import { useContext, useState, useEffect, useMemo } from "preact/hooks";
import { createContext, FunctionComponent, h } from "preact";
import { createSubscribers } from "../shared/subscribers";

export type Actions<TState> = {
  [actionName: string]: (...args: any[]) => (state: TState, store: Store<TState>) => TState;
};

export type StoreReader<TState> = {
  getState(): TState;
  subscribe(subscriber: (state: TState) => void): () => void;
};
export type StoreWriter<TState> = {
  setState(state: TState): void;
  update(updater: (state: TState) => TState): void;
};
export type Store<TState> = StoreReader<TState> & StoreWriter<TState>;

export function createReducer<TState, TSub>(
  store: StoreReader<TState>,
  reducer: (state: TState) => TSub
): StoreReader<TSub> {
  return {
    getState: () => reducer(store.getState()),
    subscribe: (subscriber) => {
      let lastSub = reducer(store.getState());
      return store.subscribe((state) => {
        const newSub = reducer(state);
        if (lastSub != newSub) {
          lastSub = newSub;
          subscriber(newSub);
        }
      });
    },
  };
}
export function createExpander<TState, TSub>(
  store: StoreWriter<TState>,
  reducer: (state: TState) => TSub,
  expander: (state: TState, sub: TSub) => TState
): StoreWriter<TSub> {
  return {
    setState: (sub) => store.update((state) => expander(state, sub)),
    update: (subUpdater) => store.update((state) => expander(state, subUpdater(reducer(state)))),
  };
}
export function createSubStore<TState, TSub>(
  store: Store<TState>,
  reducer: (state: TState) => TSub,
  expander: (state: TState, sub: TSub) => TState
): Store<TSub> {
  return {
    ...createReducer(store, reducer),
    ...createExpander(store, reducer, expander),
  };
}

export function createSelectedReader<TState, TParams extends readonly any[], TDerived>(
  store: StoreReader<TState>,
  selectors: (state: TState) => TParams,
  transformer: (...params: TParams) => TDerived
): StoreReader<TDerived> {
  const selector = createSelector(selectors, transformer);

  return {
    getState: () => selector(store.getState()),
    subscribe: (listener) =>
      store.subscribe((state) => {
        listener(selector(state));
      }),
  };
}

export function memoizeFunction<TParams extends readonly any[], TReturn>(
  func: (...params: TParams) => TReturn,
  amount: number = 1
): (...params: TParams) => TReturn {
  var cache: [TParams, TReturn][] = [];
  return (...params: TParams) => {
    var cacheHitIndex = cache.findIndex(([prevParams, _]) => params.every((newP, i) => prevParams[i] == newP));
    if (cacheHitIndex != -1) {
      var result = cache[cacheHitIndex][1];
      cache = cache.splice(cacheHitIndex, 1);
      cache.push([params, result]);
      return result;
    }

    var result = func(...params);
    if (cache.length >= amount) {
      cache.shift();
    }
    cache.push([params, result]);
    return result;
  };
}

type Mutable<T> = T extends readonly (infer I)[] ? T : T;
export function createSelector<TState, TParams extends readonly any[], TDerived>(
  selectors: (state: TState) => TParams,
  transformer: (...params: Mutable<TParams>) => TDerived
): (state: TState) => TDerived {
  const memoizedTransformer = memoizeFunction(transformer);
  return (s) => memoizedTransformer(...(selectors(s) as Mutable<TParams>));
}

export type Hooks<TState> = {
  useStore(): TState;
  useStore<T>(reducer: (state: TState) => T): T;
  useActions<TActions extends Actions<TState> = Actions<TState>>(actions: TActions): MapActions<TState, TActions>;
  bindActions<TActions extends Actions<TState> = Actions<TState>>(actions: TActions): MapActions<TState, TActions>;
  Provider: FunctionComponent;
};

type MapActions<TState, TActions extends Actions<TState>> = {
  [actionName in keyof TActions]: (...args: Parameters<TActions[actionName]>) => void;
};

interface Devtools<TState> {
  send(actionName: string, args: any[], state: TState): void;
}

export function createHooks<TState>(store: Store<TState>): Hooks<TState> {
  var context = createContext(store);
  var Provider = context.Provider;

  var devTools: Devtools<TState> = { send() {} };

  try {
    var dt = (window as any).__REDUX_DEVTOOLS_EXTENSION__.connect({});
    dt.init(store.getState());
    dt.subscribe((message: any) => {
      if (
        message.type == "DISPATCH" &&
        message.state &&
        (message.payload.type == "JUMP_TO_ACTION" || message.payload.type == "JUMP_TO_STATE")
      ) {
        store.setState(JSON.parse(message.state));
      }
    });
    devTools = {
      send(actionName: string, args: any[], state: TState) {
        var printableArgs = args.map((arg) =>
          Array.isArray(arg)
            ? "[...]"
            : typeof arg === "string"
            ? `"${arg}"`
            : typeof arg !== "object"
            ? arg
            : Object.keys(arg).length > 3
            ? "{...}"
            : `{${Object.keys(arg)
                .map((k) => `'${k}': ...`)
                .join(", ")}}`
        );
        dt.send(`${actionName}(${printableArgs.join(", ")})`, state);
      },
    };
  } catch {
    // no devtools
  }

  return {
    useStore(reducer = (s: TState) => s) {
      const store = useContext(context);
      const [state, set] = useState(() => reducer(store.getState()));
      useEffect(() => {
        let currentState = reducer(store.getState());
        if (currentState != state) {
          set(currentState);
        }
        return store.subscribe((s) => {
          const newState = reducer(s);
          if (newState != currentState) {
            currentState = newState;
            set(newState);
            console.debug("performed state update");
          } else {
            console.debug("skipped state update");
          }
        });
      }, []);
      return state;
    },
    useActions(actions) {
      const store = useContext(context);
      return useMemo(() => {
        const bound: Record<keyof typeof actions, (...args: any[]) => void> = {} as any;
        for (const actionName in actions) {
          bound[actionName] = (...args: any[]) => {
            const newState = actions[actionName](...args)(store.getState(), store);
            store.setState(newState);
            devTools.send(actionName, args, newState);
          };
        }
        return bound;
      }, [store, actions]);
    },
    bindActions(actions) {
      const bound: Record<keyof typeof actions, (...args: any[]) => void> = {} as any;
      for (const actionName in actions) {
        ((actionName) => {
          bound[actionName] = (...args: any[]) => {
            const newState = actions[actionName](...args)(store.getState(), store);
            store.setState(newState);
            devTools.send(actionName, args, newState);
          };
        })(actionName);
      }
      return bound;
    },
    Provider: ({ children }) => <Provider value={store}>{children}</Provider>,
  };
}

export function useStore<TState, TDerived>(
  store: StoreReader<TState>,
  reducer: (s: TState) => TDerived,
  inputs: any[] = []
) {
  const [state, set] = useState(() => reducer(store.getState()));
  useEffect(() => {
    let currentState = reducer(store.getState());
    if (currentState != state) {
      set(currentState);
    }
    return store.subscribe((s) => {
      const newState = reducer(s);
      if (newState != currentState) {
        currentState = newState;
        set(newState);
        console.debug("performed state update");
      } else {
        console.debug("skipped state update");
      }
    });
  }, inputs);
  return state;
}

export function createStore<TState>(state: TState): Store<TState> {
  const listeners = createSubscribers<(state: TState) => void>();
  var store: Store<TState> = {
    setState(newState) {
      state = newState;
      listeners.invoke(newState);
    },
    getState() {
      return state;
    },
    update(updater) {
      store.setState(updater(store.getState()));
    },
    subscribe: listeners.addSubscriber,
  };

  return store;
}
