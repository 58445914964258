import { createApi } from "./api";
import { Data } from "./store/types";
import { EventerClient } from "./eventerClient";
import { Event } from "../shared";

var apiInstance: ReturnType<typeof createApi> | undefined;
export function setApi(api: ReturnType<typeof createApi>): ReturnType<typeof createApi> {
  return (apiInstance = api);
}
export function getApi(throwError: boolean = false): ReturnType<typeof createApi> | undefined {
  if (apiInstance === undefined) {
    throw `api not initialised`;
  }
  return apiInstance;
}
var eventerInstance: EventerClient<Event, Data>["queue"] | undefined;
export function setEventer(eventer: EventerClient<Event, Data>["queue"]): EventerClient<Event, Data>["queue"] {
  return (eventerInstance = eventer);
}
export function getEventer(throwError: true): EventerClient<Event, Data>["queue"];
export function getEventer(throwError?: false): EventerClient<Event, Data>["queue"] | undefined;
export function getEventer(throwError: boolean = false): EventerClient<Event, Data>["queue"] | undefined {
  if (eventerInstance === undefined) {
    throw `eventer not initialised`;
  }
  return eventerInstance;
}
