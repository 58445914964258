import { h, FunctionalComponent } from "preact";
import { FlagTeam } from "../../shared";
import { Data } from "../store/types";
import { useCallback, useEffect, useState } from "preact/hooks";
import { getEventer } from "../singleton";
import { v4 } from "uuid";
import { Icon } from "./Icon";

export const TeamEditor: FunctionalComponent<{
  map: Data["map"];
  team?: FlagTeam;
}> = ({ team }) => {
  const [name, setName] = useState(team?.name ?? "");
  const [symbol, setSymbol] = useState(team?.symbol ?? "");
  const [color, setColor] = useState(team?.color ?? "");
  const [members, setMembers] = useState(team?.members?.join(", ") ?? "");
  const parsedMembers = members.split(",").map((s) => s.trim());

  const updateName = useCallback((event: Event) => {
    setName((event.target as HTMLInputElement).value);
  }, []);
  const updateSymbol = useCallback((event: Event) => {
    setSymbol((event.target as HTMLInputElement).value);
  }, []);
  const updateColor = useCallback((event: Event) => {
    setColor((event.target as HTMLInputElement).value);
  }, []);
  const updateMembers = useCallback((event: Event) => {
    setMembers((event.target as HTMLInputElement).value);
  }, []);

  useEffect(() => {
    setName(team?.name ?? "");
    setSymbol(team?.symbol ?? "");
    setColor(team?.color ?? "");
    setMembers(team?.members?.join(", ") ?? "");
  }, [team]);

  const reset = useCallback(() => {
    setName(team?.name ?? "");
    setSymbol(team?.symbol ?? "");
    setColor(team?.color ?? "");
    setMembers(team?.members?.join(", ") ?? "");
  }, [team]);

  const remove = useCallback(() => {
    if (!team) {
      throw "Invalid state";
    }
    getEventer(true)({
      type: "flag-team-remove",
      teamKey: team.key!,
    });
  }, [team]);

  const save = useCallback(() => {
    if (!color || !name || !members) {
      throw "Invalid state";
    }

    getEventer(true)({
      type: "flag-team-upsert",
      team: {
        key: team?.key ?? v4(),
        color,
        symbol,
        name,
        members: parsedMembers,
      },
    });

    if (!team) {
      setName("");
      setSymbol("");
      setColor("");
      setMembers("");
    }
  }, [team, color, symbol, name, members]);

  return (
    <div class="editor-row">
      <input value={name} onChange={updateName} />
      <input value={symbol} onChange={updateSymbol} />
      <input value={color} onChange={updateColor} />
      <input value={members} onChange={updateMembers} />
      <button
        title={team ? "Save" : "Add"}
        onClick={save}
        disabled={
          !name ||
          !symbol ||
          !color ||
          !members ||
          (name === team?.name &&
            symbol == team?.symbol &&
            color === team?.color &&
            parsedMembers.length === team.members.length &&
            parsedMembers.every((m, i) => m === team.members[i]))
        }
      >
        {team ? <Icon icon="save" /> : <Icon icon="plus-square" />}
      </button>
      <button title="Remove" onClick={remove} disabled={!team}>
        <Icon icon="x-square" />
      </button>
      <button title="Reset" onClick={reset}>
        <Icon icon="slash-square" />
      </button>
    </div>
  );
};
