import { FunctionalComponent, h } from "preact";
import { useStore, Token, useActions, actions } from "./store/store";
import { Data } from "./store/types";
import { useParticipantOverview } from "./store/participantOverview";
import { Redirect, Link, useLocation } from "wouter-preact";
import * as i18n from "./i18n";
import { useState } from "preact/hooks";
import { getEventer } from "./singleton";
import { Title } from "./Title";
import { ParticipantHeader } from "./ParticipantHeader";

const ParticipantSkillProgressEditImpl: FunctionalComponent<{
  participant: string;
  skill: string;
  type: "doing" | "done" | "both";
  action: "confirm" | "retract";
  data: Data;
}> = ({ participant, skill, data, type, action }) => {
  const overview = useParticipantOverview(participant);
  const [, setStoring] = useState(false);
  const guide = useStore((s) => s.guide);
  const { setGuide } = useActions(actions);
  const [, setLocation] = useLocation();

  if (!overview) {
    return <div>Loading</div>;
  }

  const p = overview.participant;
  const s = data.skills.keyed[skill];

  async function submitProgressChange() {
    try {
      const g = guide!;
      setStoring(true);
      if (type != "both") {
        confirm(participant, skill, g, type, action);
      } else {
        var order = action == "confirm" ? (["doing", "done"] as const) : (["done", "doing"] as const);
        confirm(participant, skill, g, order[0], action);
        confirm(participant, skill, g, order[1], action);
      }
      setLocation(`/${i18n.participant}/${participant}/${i18n.skill}/${skill}`);
    } finally {
      setStoring(false);
    }
  }

  return (
    <div>
      <Title>
        Voortgang aanpassen van {s.name} door {p.fullName}
      </Title>
      <ParticipantHeader participant={p} levels={data.levels} />
      <h2>
        {s.name} <small class="text-muted">({data.categories.keyed[s.category].name})</small>
      </h2>
      {s.description && <p>{s.description}</p>}

      <div class="alert alert-danger" role="alert">
        <h3>Bevestig het {action == "confirm" ? "opslaan" : "ongedaan maken"}</h3>
        <p class="lead">
          Weet je het zeker dat je wilt <strong>{action == "confirm" ? "opslaan" : "ongedaan maken"}</strong> dat{" "}
          {p.fullName}{" "}
          <strong>{type == "doing" ? `is begonnen` : type == "done" ? "klaar is" : "is begonnen en klaar is"}</strong>{" "}
          met {s.name}?
        </p>
        <hr />
        <div class="d-flex mx-n2 align-items-baseline flex-wrap">
          <label for="guide" class="mx-2">
            Leiding die het bevestigd:{" "}
          </label>
          <select
            class="form-select-sm mx-2"
            id="guide"
            name="guide"
            onChange={(evt) => setGuide((evt.target as HTMLSelectElement).value)}
          >
            <option selected={!guide} disabled>
              Kies een leiding
            </option>
            {data.participants.guideKeys.map((g) => (
              <option selected={g == guide} value={g}>
                {data.participants.keyed[g].fullName}
              </option>
            ))}
          </select>
          <button class="btn btn-danger ms-4 mx-2" onClick={submitProgressChange} disabled={!guide}>
            Bevestig
          </button>
          <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}`}>
            <a class="btn btn-secondary mx-2">Annuleer</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const ParticipantSkillProgressEdit: FunctionalComponent<{
  participant: string;
  skill: string;
  type: "doing" | "done" | "both";
  action: "confirm" | "retract";
}> = ({ participant, skill, type, action }) => {
  var data = useStore((s) => s.data);
  var session = useStore((s) => s.session);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  if (session.token == null) {
    return (
      <div class="alert alert-warning" role="alert">
        Log eerst in
      </div>
    );
  }
  if (!session.claims.permissions.write) {
    return (
      <div class="alert alert-warning" role="alert">
        Log in als leiding
      </div>
    );
  }
  if (!(skill in data.skills.keyed)) {
    return <Redirect href={`/${i18n.participant}/${participant}`}></Redirect>;
  }
  if (!(participant in data.participants.keyed)) {
    return <Redirect href="/" />;
  }
  return (
    <ParticipantSkillProgressEditImpl participant={participant} skill={skill} type={type} action={action} data={data} />
  );
};

function confirm(participant: string, skill: string, g: string, type: "doing" | "done", action: "confirm" | "retract") {
  getEventer(true)({
    type: "progress-update",
    participant,
    skill,
    guide: g,
    progress: type,
    action,
  });
}
