import { h, render, Component, createRef, FunctionalComponent } from "preact";
import { Flag, FlagTeam } from "../../shared";
import { useEffect, useState } from "preact/hooks";
import { Marker } from "./Marker";
import { MarkerInfo } from "./MarkerInfo";
import { last } from "./utils";
import { createMarker, FlagHuntState, useFlagHunt } from "./values";
import { Data, Dictionary } from "../store/types";
import { State, store } from "../store/store";
import { loading } from "../maps-api";
import { TopPanel } from "./TopPanel";
import { Dashboard } from "./Dashboard";

const TopPanelWrap: FunctionalComponent<{
  marker: google.maps.Marker | undefined;
  center: google.maps.LatLngLiteral;
}> = ({ marker, center }) => {
  const [markerCoords, setMarkerCoords] = useState(center);
  useEffect(() => setMarkerCoords(center), [center]);
  useEffect(() => {
    const currentMarker = marker;
    const listener = currentMarker?.addListener("drag", () => {
      const pos = currentMarker.getPosition();
      if (pos) {
        setMarkerCoords({
          lat: pos.lat(),
          lng: pos.lng(),
        });
      }
    });
    return () => listener?.remove();
  }, [marker]);

  return <TopPanel markerCoords={markerCoords} />;
};

export const FlagHuntInitialized = () => {
  const flagHuntState = useFlagHunt();
  if (!flagHuntState) {
    return <div>Not initialized</div>;
  }
  return <FlagHunt flagHuntState={flagHuntState} />;
};

export class FlagHunt extends Component<{ flagHuntState: FlagHuntState }, { canWrite: boolean }> {
  ref = createRef<HTMLDivElement>();
  markers: Dictionary<string, Marker> = {};
  map!: google.maps.Map;
  storeSubscription?: () => void;
  draggableMarker?: google.maps.Marker;
  observer = new ResizeObserver(() => this.map && google.maps.event.trigger(this.map, "resize"));

  fakeResize = async () => {
    this.map.notify("height");
    await new Promise((resolve) => setTimeout(resolve, 10));
    this.ref.current!.style.marginBottom = "1.1px";
    this.map.notify("height");
    await new Promise((resolve) => setTimeout(resolve, 10));
    google.maps.event.trigger(this.map, "resize");
    await new Promise((resolve) => setTimeout(resolve, 10));
    this.ref.current!.style.marginBottom = "0px";
    await new Promise((resolve) => setTimeout(resolve, 10));
    google.maps.event.trigger(this.map, "resize");
  };

  focusFlag = (flag: Flag) => {
    this.map.panTo(flag.location);

    if (this.markers[flag.key]) {
      this.bounceMarker(this.markers[flag.key]!);
    }
  };

  mapData: Data["map"] = {
    captures: {
      byFlag: {},
      byTeam: {},
      keyed: {},
      keys: [],
    },
    flags: {
      keyed: {},
      keys: [],
    },
    teams: {
      keyed: {},
      keys: [],
    },
  };

  constructor() {
    super();
    this.setState({
      canWrite: false,
    });
  }

  async componentDidMount() {
    await loading;
    if (!this.ref.current) {
      throw "Invalid operation, no ref";
    }

    this.observer.observe(this.ref.current);

    var mapTypeIds: (google.maps.MapTypeId | "OSM")[] = [];
    for (var type of Object.values(google.maps.MapTypeId)) {
      mapTypeIds.push(type);
    }
    mapTypeIds.push("OSM");

    this.map = new google.maps.Map(this.ref.current, {
      center: this.props.flagHuntState.center,
      zoom: 15,
      restriction: {
        latLngBounds: this.props.flagHuntState.bounds,
      },
      mapTypeId: "OSM",
      mapTypeControlOptions: {
        mapTypeIds: mapTypeIds,
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_RIGHT,
      },
      streetViewControl: false,
    });

    this.map.mapTypes.set(
      "OSM",
      new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          var tilesPerGlobe = 1 << zoom;
          var x = coord.x % tilesPerGlobe;
          if (x < 0) {
            x = tilesPerGlobe + x;
          }
          // See above example if you need smooth wrapping at 180th meridian
          return "https://tile.openstreetmap.org/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
        },
        tileSize: new google.maps.Size(256, 256),
        name: "OSM",
        alt: "OpenStreetMap",
        maxZoom: 18,
      })
    );

    this.draggableMarker = new google.maps.Marker({
      map: this.state.canWrite ? this.map : undefined,
      position: this.props.flagHuntState.center,
      draggable: true,
      crossOnDrag: true,
    });

    // Construct the polygon.
    // var poly = new google.maps.Polygon({
    //     paths: [worldCoords, areaBounds],
    //     strokeColor: '#000000',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: '#000000',
    //     fillOpacity: 0.35
    // });

    // poly.setMap(this.map);

    this.storeSubscription = store.subscribe((s) => this.storeUpdate(s));

    this.storeUpdate(store.getState());
  }

  componentWillUnmount() {
    this.observer.disconnect();
    this.storeSubscription?.();
  }

  storeUpdate(state: State) {
    const shouldWrite = !!state.session.claims.permissions.write;
    if (this.state.canWrite != shouldWrite) {
      this.setState((s) => ({
        ...s,
        canWrite: shouldWrite,
      }));

      this.draggableMarker?.setMap(shouldWrite ? this.map : null);
    }
    if (!state.data || state.data.map == this.mapData) {
      return;
    }

    const mapBefore = this.mapData;
    this.mapData = state.data.map;

    if (state.data.map.teams != mapBefore.teams) {
      this.teamsUpdated(mapBefore, state.data.map);
    }

    if (state.data.map.flags != mapBefore.flags) {
      this.flagsUpdated(mapBefore, state.data.map);
    }

    if (state.data.map.captures != mapBefore.captures) {
      this.capturesUpdated(state.data.map);
    }
  }

  teamsUpdated(before: Data["map"], after: Data["map"]) {
    for (const marker of Object.values(this.markers)) {
      const captureKey = last(after.captures.byFlag[marker!.flag.key]);
      const capture = captureKey ? after.captures.keyed[captureKey] : undefined;
      if (!capture) {
        continue;
      }

      const teamAfter = before.teams.keyed[capture.teamKey];
      marker!.marker.setIcon(this.createMarkerIconImage(teamAfter));
      marker!.marker.setLabel(teamAfter?.symbol ?? "");
    }
  }

  flagsUpdated(before: Data["map"], after: Data["map"]) {
    const keysBefore = new Set(before.flags.keys);
    for (const key of after.flags.keys) {
      const flag = after.flags.keyed[key]!;

      if (keysBefore.has(key)) {
        this.markers[key]?.marker.setPosition(flag.location);
        keysBefore.delete(key);
        continue;
      }

      const markerDiv = document.createElement("div");
      render(<MarkerInfo flagKey={key} />, markerDiv);

      const captureKey = last(after.captures.byFlag[key]);
      const capture = captureKey ? after.captures.keyed[captureKey]! : undefined;
      const team = capture ? after.teams.keyed[capture?.teamKey] : undefined;
      const marker = new google.maps.Marker({
        map: this.map,
        position: flag.location,
        icon: this.createMarkerIconImage(team),
        label: team?.symbol ?? "",
        animation: google.maps.Animation.DROP,
        title: flag.name,
      });
      const window = new google.maps.InfoWindow({
        content: markerDiv,
        disableAutoPan: true,
      });
      this.markers[key] = {
        flag,
        marker,
        window,
        capture: undefined,
        dropMoment: new Date(),
        animateMoment: new Date(),
      };
      marker.addListener("click", () => {
        for (const marker of Object.values(this.markers)) {
          marker!.window.close();
        }
        window.open(this.map, marker);
      });
    }

    for (const keyBefore of keysBefore) {
      this.markers[keyBefore]?.marker.unbindAll();
      this.markers[keyBefore]?.marker.setMap(null);
      delete this.markers[keyBefore];
    }
  }

  private createMarkerIconImage(captureTeam: FlagTeam | undefined): google.maps.Icon {
    return {
      url: createMarker(captureTeam, 15, 2),
      anchor: new google.maps.Point(15, 15),
      labelOrigin: new google.maps.Point(15, 15),
    };
  }

  capturesUpdated(after: Data["map"]) {
    for (const marker of Object.values(this.markers)) {
      const afterCaptureKeys = after.captures.byFlag[marker!.flag.key];
      const afterCaptureKey =
        (afterCaptureKeys?.length ?? 0) > 0 ? afterCaptureKeys![afterCaptureKeys!.length - 1] : undefined;

      const capture = afterCaptureKey ? after.captures.keyed[afterCaptureKey] : undefined;

      if (marker!.capture?.teamKey != capture?.teamKey) {
        const team = capture ? after.teams.keyed[capture.teamKey] : undefined;
        marker!.marker.setIcon(this.createMarkerIconImage(team));
        marker!.marker.setLabel(team?.symbol ?? "");

        this.bounceMarker(marker!);
      }

      marker!.capture = capture;
    }
  }

  bounceMarker(marker: Marker) {
    if (+new Date() - +marker.dropMoment > 1000) {
      marker.marker.setAnimation(google.maps.Animation.BOUNCE);
      const moment = new Date();
      marker.animateMoment = moment;
      setTimeout(() => {
        if (marker.animateMoment !== moment) {
          return;
        }

        marker!.marker.setAnimation(null);
      }, 1000);
    }
  }

  render() {
    return (
      <div class="map-container">
        <div class="top-panel">
          <TopPanelWrap marker={this.draggableMarker} center={this.props.flagHuntState.center} />
        </div>
        <div class="map-wrapper">
          <Dashboard focusFlag={this.focusFlag} />
          <div class="map" ref={this.ref} />
        </div>
      </div>
    );
  }
}
