import { FunctionalComponent, h, JSX } from "preact";
import { useStore } from "./store/store";
import { useLocation, useRoute } from "wouter-preact";
import * as i18n from "./i18n";
import { useMemo } from "preact/hooks";
import { t } from "./store/i18l";

export const RoutingParticipantsSelector: FunctionalComponent<{
  class?: JSX.HTMLAttributes["class"];
}> = ({ class: c }) => {
  var [, setLocation] = useLocation();
  var [, participantMatch] = useRoute(`/${i18n.participant}/:name/:path*`);
  var [, skillMatch] = useRoute(`/${i18n.skill}/:skill/:path*`);
  var updateParticipant = (type: "participant" | "skill", key: string) => {
    let location = `/${i18n[type]}/${key}`;
    if (type == "participant" && participantMatch && participantMatch.path) {
      location += `/${participantMatch.path}`;
    }
    setLocation(location);
  };
  var participant = participantMatch && participantMatch.name && unescape(participantMatch.name);
  var skill = skillMatch && skillMatch.skill && unescape(skillMatch.skill);
  return (
    <ParticipantsSelector
      class={c}
      onChange={updateParticipant}
      selectedParticipant={participant || undefined}
      selectedSkill={skill || undefined}
    />
  );
};
interface ParticipantsSelectorProps {
  onChange?: (type: "participant" | "skill", key: string) => void;
  selectedParticipant?: string;
  selectedSkill?: string;
}
export const ParticipantsSelector: FunctionalComponent<
  ParticipantsSelectorProps & { class?: JSX.HTMLAttributes["class"] }
> = ({ onChange, selectedParticipant, selectedSkill, class: c }) => {
  var data = useStore((s) => s.data);
  var Verkenners = t("Verkenners");

  const items = useMemo(() => {
    if (!data) return [] as (Option | OptGroup)[];
    var { participants, skills, categories } = data;
    const scoutItems = [
      {
        label: Verkenners,
        items: participants.scoutKeys.map((key) => ({
          key: `participant:${key}`,
          label: participants!.keyed[key].fullName,
        })),
      },
    ];
    const skillItems = Object.keys(skills.byCategory).map((c) => ({
      label: categories.keyed[c].name,
      items: skills!.byCategory[c].map((skill) => ({
        key: `skill:${skill.key}`,
        label: skill.name,
      })),
    }));
    return [...(!participants.scoutKeys.length ? [] : scoutItems), ...(!skills.keys.length ? [] : skillItems)];
  }, [data && data.categories, data && data.participants, data && data.skills]);

  return (
    <Selector
      class={c}
      initialItemText="Kies je naam of een techniek"
      initialItemWhenSelectedText="Home"
      onChange={(key) => onChange && onChange(key.split(":")[0] as any, key.split(":")[1])}
      selected={selectedParticipant ? `participant:${selectedParticipant}` : `skill:${selectedSkill}`}
      items={items}
    />
  );
};

interface Option {
  label: string;
  key: string;
}
interface OptGroup {
  label: string;
  items: Option[];
}
interface SelectorProps {
  items: (Option | OptGroup)[];
  initialItemText: string;
  initialItemWhenSelectedText?: string;
  selected?: string;
  onChange?: (key: string) => void;
}
export const Selector: FunctionalComponent<SelectorProps & { class?: JSX.HTMLAttributes["class"] }> = ({
  items,
  selected,
  onChange,
  initialItemText,
  initialItemWhenSelectedText,
  class: c,
}) => {
  return (
    <select class={`form-select ${c}`} onChange={(evt) => onChange && onChange(evt.currentTarget.value)}>
      <option selected={!selected}>
        {selected ? initialItemWhenSelectedText || initialItemText : initialItemText}
      </option>
      {items.map((item) =>
        "key" in item ? (
          <Option option={item} selectedKey={selected} />
        ) : (
          <optgroup label={item.label}>
            {item.items.map((item) => (
              <Option option={item} selectedKey={selected} />
            ))}
          </optgroup>
        )
      )}
    </select>
  );
};
const Option: FunctionalComponent<{ option: Option; selectedKey?: string }> = ({
  selectedKey,
  option: { key, label },
}) => {
  return (
    <option selected={selectedKey == key} value={[key]}>
      {label}
    </option>
  );
};
