import { FunctionComponent, Fragment, h } from "preact";
import { Data } from "./store/types";
import { useStore } from "./store/store";
import { Redirect } from "wouter-preact";
import { groupBy } from "./functional";
import { useMemo } from "preact/hooks";
import { ParticipantRender } from "./Utilities";
import { join } from "./utils";
import { Title } from "./Title";

const SkillOverviewImpl: FunctionComponent<{ skill: string; data: Data }> = ({ skill, data }) => {
  var progress = useMemo(
    () =>
      groupBy(data.participants.scoutKeys, (pKey) => {
        const participantskills = data.progress.byParticipantThenSkill[pKey];
        if (!participantskills || !participantskills[skill]) {
          return "todo";
        }
        return "done" in participantskills[skill] ? "done" : "doing";
      }),
    [skill, data.progress.byParticipantThenSkill]
  );

  var skillObject = data.skills.keyed[skill];
  return (
    <Fragment>
      <Title>Voortgang van {skillObject.name}</Title>
      <h1>{skillObject.name}</h1>
      <h2>Bezig ({progress.doing ? progress.doing.length : 0})</h2>
      {!progress.doing ? (
        <Fragment>Niemand</Fragment>
      ) : (
        join(
          <Fragment>, </Fragment>,
          <Fragment> en </Fragment>,
          progress.doing.map((pKey) => <ParticipantRender participant={data.participants.keyed[pKey]} />)
        )
      )}
      <h2>Nog niet begonnen ({progress.todo ? progress.todo.length : 0})</h2>
      {!progress.todo ? (
        <Fragment>Niemand</Fragment>
      ) : (
        join(
          <Fragment>, </Fragment>,
          <Fragment> en </Fragment>,
          progress.todo.map((pKey) => <ParticipantRender participant={data.participants.keyed[pKey]} />)
        )
      )}
      <h2>Klaar ({progress.done ? progress.done.length : 0})</h2>
      {!progress.done ? (
        <Fragment>Niemand</Fragment>
      ) : (
        join(
          <Fragment>, </Fragment>,
          <Fragment> en </Fragment>,
          progress.done.map((pKey) => <ParticipantRender participant={data.participants.keyed[pKey]} />)
        )
      )}
    </Fragment>
  );
};

export const SkillOverview: FunctionComponent<{ skill: string }> = ({ skill }) => {
  var data = useStore((s) => s.data);

  if (!data) {
    return <div>Loading...</div>;
  }
  if (!(skill in data.skills.keyed)) {
    return <Redirect href="/" />;
  }
  return <SkillOverviewImpl skill={skill} data={data} />;
};
