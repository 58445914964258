import { createStore, createHooks, createSelectedReader, memoizeFunction, Store } from "../storelib";
import produce from "immer";
import { Data, Dictionary } from "./types";
import { createStorePersistence, createPersister } from "../storePersister";
import { handleTokenExpiration } from "./session";
import { transformParticipantDetails, transformParticipantOverview } from "./transformations";
import { map } from "../functional";
import "./indexed";

export type State = {
  debug: boolean;
  connected: boolean;
  data: Data | undefined;
  session: Session;
  stayLoggedIn: boolean;
  guide: string | undefined;
  alerts: Alert[];
  features: {
    stars: boolean;
    patrols: boolean;
    flagHunt: boolean | "force";
  };
  "menu-logo": string;
  strings: {
    [key: string]: string;
  };
  flagHunt?: {
    bounds: google.maps.LatLngLiteral[];
  };
};
export type Alert = ({ title: string; text?: string } | { text: string; title?: string }) & {
  severity?: "primary" | "secondary" | "success" | "info" | "warning" | "danger";
};
export type Token = {
  token: string;
  expiration: Date;
};
export type Claims = {
  permissions: Dictionary<"read" | "write", true>;
};
export type Session = {
  token: Token | undefined;
  claims: Claims;
};
export const actions = {
  setGuide(guide: string | undefined): (state: State) => State {
    return produce((draft: State) => {
      draft.guide = guide;
    });
  },
  openAlert({ duration, ...alert }: Alert & { duration?: number }): (state: State, store: Store<State>) => State {
    setTimeout(
      () => {
        store.update((s) => ({
          ...s,
          alerts: s.alerts.filter((a) => a != alert),
        }));
      },
      duration === undefined ? 2000 : duration
    );
    return produce((draft: State) => {
      draft.alerts.push(alert);
    });
  },
};

const sessionPersister = createPersister<Session>({
  key: "token",
  parse(sessionString) {
    const pseudoSession: Session = JSON.parse(sessionString);
    return {
      claims: pseudoSession.claims,
      token: !pseudoSession.token
        ? undefined
        : {
            token: pseudoSession.token.token,
            expiration: new Date(pseudoSession.token.expiration as unknown as string),
          },
    };
  },
  stringify: JSON.stringify,
});
const guidePersister = createPersister<string>({
  key: "guide",
  parse: (id) => id,
  stringify: (id) => id,
});

const existingSession = sessionPersister.value;
const sessionIsValid =
  !!existingSession &&
  !!existingSession.token &&
  "expiration" in existingSession.token &&
  existingSession.token.expiration > new Date();

export const store = createStore<State>({
  debug: false,
  connected: true,
  data: undefined,
  session: (sessionIsValid && existingSession) || {
    token: undefined,
    claims: {
      permissions: {},
    },
  },
  stayLoggedIn: sessionIsValid,
  alerts: [],
  guide: guidePersister.value,
  features: {
    stars: /[?&]stars($|&|=\w)/.test(document.location.search),
    patrols: false,
    flagHunt: true,
  },
  "menu-logo": "",
  strings: {},
});
if (sessionIsValid) {
  handleTokenExpiration(existingSession.token);
  sessionPersister.onChange((s) => handleTokenExpiration(s.token));
}

createStorePersistence<State, Session>(store, sessionPersister, {
  getter: (state) => state.session,
  setter: (state, session) =>
    produce(state, (draft) => {
      draft.session = session;
    }),
  shouldPersist: (state) => state.stayLoggedIn,
});
createStorePersistence<State, string | undefined>(store, guidePersister, {
  getter: (state) => state.guide,
  setter: (state, guide) =>
    produce(state, (draft) => {
      draft.guide = guide;
    }),
});

export const { useStore, useActions, bindActions, Provider } = createHooks(store);

const detailsTransformers: Dictionary<string, typeof transformParticipantDetails> = {};
const detailsTransformersCache = getOrAdd(detailsTransformers, () => memoizeFunction(transformParticipantDetails));
export const participantDetailsStore = createSelectedReader(
  store,
  (state) =>
    [
      state.data && state.data.participants,
      state.data && state.data.progress,
      state.data && state.data.categories,
      state.data && state.data.skills,
      state.data && state.data.levels,
    ] as const,
  (participants, progress, categories, skills, levels) => {
    return (
      participants &&
      progress &&
      categories &&
      skills &&
      levels &&
      map(participants.keyed).to((pKey) =>
        detailsTransformersCache(pKey.key)(pKey, progress, categories, skills, levels)
      )
    );
  }
);

const overviewTransformers: Dictionary<string, typeof transformParticipantOverview> = {};
const overviewTransformersCache = getOrAdd(overviewTransformers, () => memoizeFunction(transformParticipantOverview));
export const participantOverviewStore = createSelectedReader(
  participantDetailsStore,
  (state) => [state] as const,
  (details) => {
    return !details
      ? undefined
      : map(details).to((details) => overviewTransformersCache(details.participant.key)(details));
  }
);

function getOrAdd<TKey extends keyof any, TValue>(
  items: Dictionary<TKey, TValue>,
  add: (key: TKey) => TValue
): (key: TKey) => TValue {
  return (key) => (key in items ? items[key]! : (items[key] = add(key)));
}

(window as any).store = store;
