import { FunctionalComponent, h, Fragment } from "preact";
import { useStore } from "./store/store";
import { Data } from "./store/types";
import { useParticipantOverview } from "./store/participantOverview";
import { Redirect, Link } from "wouter-preact";
import * as i18n from "./i18n";
import { join } from "./utils";
import { Title } from "./Title";
import { ParticipantHeader } from "./ParticipantHeader";

import dayjs = require("dayjs");
import "dayjs/locale/nl";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("nl");

const ParticipantSkillImpl: FunctionalComponent<{
  participant: string;
  skill: string;
  data: Data;
}> = ({ participant, skill, data }) => {
  const overview = useParticipantOverview(participant);
  const session = useStore((s) => s.session);

  if (!overview) {
    return <div>Loading</div>;
  }

  const details = overview.details;

  const p = overview.participant;
  const s = data.skills.keyed[skill];
  const ss = details.byCategory[s.category].byLevel[data.levels.keyed[s.level].rank].skills.find(
    (s) => s.skill.key == skill
  )!;
  const progress = (data.progress.byParticipantThenSkill[p.key] || {})[ss.skill.key] || {};

  const guides = data.guides.byCategory[s.category];

  return (
    <div>
      <Title>
        {s.name} door {p.fullName}
      </Title>
      <ParticipantHeader participant={p} levels={data.levels} />
      <h2>
        {s.name} <small class="text-muted">({data.categories.keyed[s.category].name})</small>
      </h2>
      {s.description && <p>{s.description}</p>}

      <ul>
        <li>
          Niveau techniek: <small class="badge bg-secondary">{data.levels.keyed[s.level].name}</small>
        </li>
        {progress.doing ? (
          <li>
            Begonnen op <ProgressDate date={progress.doing.when} /> (bevestigd door{" "}
            {data.participants.keyed[progress.doing.guide].firstName})
          </li>
        ) : (
          <li>Nog niet begonnen</li>
        )}
        {progress.done ? (
          <li>
            Gehaald op <ProgressDate date={progress.done.when} /> (bevestigd door{" "}
            {data.participants.keyed[progress.done.guide].firstName})
          </li>
        ) : (
          <li>Nog niet gehaald</li>
        )}
      </ul>
      {guides && guides.length ? (
        <p>
          Je voortgang kan gecontroleerd worden door{" "}
          {join<any>(
            ", ",
            " of ",
            guides.map(({ guide }) => data.participants.keyed[guide].firstName)
          )}
        </p>
      ) : (
        <p>Vraag aan de leiding wie je hier verder mee kan helpen</p>
      )}
      {session.claims.permissions.write && (
        <Fragment>
          <h3>Voortgang aanpassen (leiding)</h3>
          <p class="lead">Pas hier de voortgang van {p.fullName} aan</p>
          <ul class="list-unstyled">
            <li class="my-2">
              Is begonnen
              {!progress.doing ? (
                <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}/${i18n.confirm}/doing`}>
                  <a class="btn btn-primary mx-2">Bevestig</a>
                </Link>
              ) : (
                <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}/${i18n.retract}/doing`}>
                  <a class="btn btn-danger mx-2">Maak ongedaan</a>
                </Link>
              )}
            </li>
            <li class="my-2">
              Heeft het gehaald
              {!progress.done ? (
                <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}/${i18n.confirm}/done`}>
                  <a class="btn btn-primary mx-2">Bevestig</a>
                </Link>
              ) : (
                <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}/${i18n.retract}/done`}>
                  <a class="btn btn-danger mx-2">Maak ongedaan</a>
                </Link>
              )}
            </li>
            {!progress.doing && !progress.done && (
              <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}/${i18n.confirm}/both`}>
                <a class="btn btn-primary">Bevestig beide</a>
              </Link>
            )}
            {progress.doing && progress.done && (
              <Link href={`/${i18n.participant}/${participant}/${i18n.skill}/${skill}/${i18n.retract}/both`}>
                <a class="btn btn-danger">Maak beide ongedaan</a>
              </Link>
            )}
          </ul>
        </Fragment>
      )}
    </div>
  );
};

const ProgressDate: FunctionalComponent<{ date: Date | string }> = ({ date }) => {
  return <Fragment>{dayjs(date).format("LLLL")}</Fragment>;
};

export const ParticipantSkill: FunctionalComponent<{
  participant: string;
  skill: string;
}> = ({ participant, skill }) => {
  var data = useStore((s) => s.data);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  if (!(skill in data.skills.keyed)) {
    return <Redirect href={`/${i18n.participant}/${participant}`}></Redirect>;
  }
  if (!(participant in data.participants.keyed)) {
    return <Redirect href="/" />;
  }
  return <ParticipantSkillImpl participant={participant} skill={skill} data={data} />;
};
