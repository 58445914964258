import { h, FunctionComponent } from "preact";
import { useStore } from "./store";
import Markup from "preact-markup";
import { Rank } from "../Rank";

export function t(prop: string, def?: string) {
  return useStore((s) => s.strings[prop] ?? def ?? prop);
}

export const Tm: FunctionComponent<{ prop: string; def?: string }> = ({ prop, def }) => {
  const text = t(prop, def);
  return <Markup markup={text} components={{ Rank }} trim={false} />;
};
