import { h, FunctionalComponent } from "preact";
import { Flag } from "../../shared";
import { useCallback, useEffect, useState } from "preact/hooks";
import { getEventer } from "../singleton";
import { v4 } from "uuid";
import { Icon } from "./Icon";

export const FlagEditor: FunctionalComponent<{
  flag?: Flag;
  markerCoords?: google.maps.LatLngLiteral;
}> = ({ flag, markerCoords }) => {
  const [name, setName] = useState(flag?.name ?? "");
  const [points, setPoints] = useState(flag?.points ?? 1);
  const [description, setDescription] = useState(flag?.description ?? "");
  const [lat, setLat] = useState(flag?.location.lat ?? markerCoords?.lat ?? 0);
  const [lng, setLng] = useState(flag?.location.lng ?? markerCoords?.lng ?? 0);

  const updateName = useCallback((event: Event) => {
    setName((event.target as HTMLInputElement).value);
  }, []);

  const updatePoints = useCallback((event: Event) => {
    setPoints(Number.parseInt((event.target as HTMLInputElement).value));
  }, []);

  const updateDescription = useCallback((event: Event) => {
    setDescription((event.target as HTMLInputElement).value);
  }, []);
  const updateLat = useCallback((event: Event) => {
    setLat(Number.parseFloat((event.target as HTMLInputElement).value));
  }, []);
  const updateLng = useCallback((event: Event) => {
    setLng(Number.parseFloat((event.target as HTMLInputElement).value));
  }, []);

  useEffect(() => {
    if (markerCoords) {
      setLat(markerCoords.lat);
      setLng(markerCoords.lng);
    }
  }, [markerCoords, lat, lng]);

  useEffect(() => {
    setName(flag?.name ?? "");
    setPoints(flag?.points ?? 1);
    setDescription(flag?.description ?? "");
    setLat(flag?.location.lat ?? 0);
    setLng(flag?.location.lng ?? 0);
  }, [flag]);

  const reset = useCallback(() => {
    setName(flag?.name ?? "");
    setPoints(flag?.points ?? 1);
    setDescription(flag?.description ?? "");
    setLat(flag?.location.lat ?? 0);
    setLng(flag?.location.lng ?? 0);
  }, [flag]);

  const remove = useCallback(() => {
    if (!flag) {
      throw "Invalid state";
    }
    getEventer(true)({
      type: "flag-remove",
      flagKey: flag.key!,
    });
  }, [flag]);

  const save = useCallback(() => {
    if (!name || !lat || !lng) {
      throw "Invalid state";
    }

    getEventer(true)({
      type: "flag-upsert",
      flag: {
        key: flag?.key ?? v4(),
        name,
        points,
        description,
        location: {
          lat,
          lng,
        },
      },
    });

    if (!flag) {
      setName("");
      setPoints(1);
      setDescription("");
      setLat(0);
      setLng(0);
    }
  }, [flag, name, points, description, lat, lng]);

  return (
    <div class="editor-row">
      <input value={name} onChange={updateName} />
      <input value={points} onChange={updatePoints} type="number" step={1} min={0} />
      <input value={description} onChange={updateDescription} />
      <input value={lat} onChange={updateLat} />
      <input value={lng} onChange={updateLng} />
      <button
        title={flag ? "Save" : "Add"}
        onClick={save}
        disabled={
          !name ||
          !lat ||
          !lng ||
          (name === flag?.name &&
            points == flag?.points &&
            description == flag.description &&
            lat === flag?.location.lat &&
            lng === flag?.location.lng)
        }
      >
        {flag ? <Icon icon="save" /> : <Icon icon="plus-square" />}
      </button>
      <button title="Remove" onClick={remove} disabled={!flag}>
        <Icon icon="x-square" />
      </button>
      <button title="Reset" onClick={reset}>
        <Icon icon="slash-square" />
      </button>
    </div>
  );
};
