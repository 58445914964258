import { h, FunctionalComponent, FunctionComponent, toChildArray } from "preact";
import { Route, Switch, Redirect, useRoute } from "wouter-preact";
import { Overview } from "./Overview";
import { Container } from "./Bootstrap";
import * as i18n from "./i18n";
import { ParticipantOverview } from "./ParticipantOverview";
import { ParticipantSkill } from "./ParticipantSkill";
import { ParticipantSkills } from "./ParticipantSkills";
import { Nav, Password } from "./Nav";
import { ParticipantSkillProgressEdit } from "./ParticipantSkillProgressEdit";
import { useStore } from "./store/store";
import { GroupOverview } from "./GroupOverview";
import { SkillOverview } from "./SkillOverview";
import { Title } from "./Title";
import { ParticipantProgress } from "./ParticipantProgress";
import { PatrolsOverview } from "./PatrolsOverview";
import { Alerts } from "./Alerts";
import { ParticipantEdit } from "./ParticipantEdit";
import { ParticipantProgressMulti } from "./ParticipantProgressMulti";
import { FlagHuntInitialized } from "./flag-hunt/FlagHunt";
import { Tm } from "./store/i18l";
// import { FlagHunt } from './flag-hunt/FlagHunt';

export const App: FunctionalComponent = () => {
  const debug = useStore((s) => s.debug);
  const width = 90;
  const height = Math.tan((30 / 180) * Math.PI) * width;
  const [isFlagHuntByRoute] = useRoute("/vlaggenroof");
  const isForcedFlagHunt = useStore((s) => s.features.flagHunt === "force");

  const isFlagHunt = isFlagHuntByRoute || isForcedFlagHunt;

  return (
    <div class="d-flex flex-column" style={{ isolation: "isolate" }}>
      <Title>
        <Tm prop="Title" def="Scoutingtechnieken" />
      </Title>
      <div class="alerts-container">
        <Alerts class="alerts container" />
      </div>
      <header class="sticky-top mb-2 bg-white shadow-sm d-print-none">
        <Nav />
      </header>
      <div class="scrollable-body mt-n2">
        <Container fluid={isFlagHunt} style="position: relative;">
          {debug && (
            <div
              style={{
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: -1,
                color: "lightgray",
              }}
            >
              <svg viewBox={`0 0 ${width} ${height}`} style={{ width: "100%", height: "100%" }}>
                <g>
                  <text
                    x={width / 2}
                    y={height / 2}
                    dominant-baseline="middle"
                    text-anchor="middle"
                    fill="currentColor"
                    transform={`rotate(30, ${width / 2}, ${height / 2})`}
                  >
                    Development
                  </text>
                </g>
              </svg>
            </div>
          )}
          {isForcedFlagHunt ? (
            <FlagHuntInitialized />
          ) : (
            <Switch>
              <Route path={`/vlaggenroof`}>
                <FlagHuntInitialized />
              </Route>
              <Route path={`/${i18n.group}`}>
                <RequireAuth>
                  <GroupOverview />
                </RequireAuth>
              </Route>
              <Route path={`/${i18n.patrol}/:patrol`}>
                <Redirect href={`/${i18n.patrol}/`} />
              </Route>
              <Route path={`/${i18n.patrol}`}>
                <RequireAuth>
                  <PatrolsOverview />
                </RequireAuth>
              </Route>
              <Route path={`/${i18n.skill}/:skill`}>
                {(params: any) => (
                  <RequireAuth>
                    <SkillOverview skill={unescape(params.skill)} />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantOverview participant={unescape(params.name)} />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/${i18n.skill}/:skill`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantSkill participant={unescape(params.name)} skill={unescape(params.skill)} />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/${i18n.skill}/:skill/${i18n.confirm}/:progress`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantSkillProgressEdit
                      participant={unescape(params.name)}
                      skill={unescape(params.skill)}
                      type={params.progress}
                      action="confirm"
                    />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/${i18n.skill}/:skill/${i18n.retract}/:progress`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantSkillProgressEdit
                      participant={unescape(params.name)}
                      skill={unescape(params.skill)}
                      type={params.progress}
                      action="retract"
                    />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/${i18n.skill}`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantSkills participant={unescape(params.name)} />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/@all/${i18n.progress}`}>
                {() => (
                  <RequireAuth>
                    <ParticipantProgressMulti />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/${i18n.progress}`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantProgress participant={unescape(params.name)} />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/${i18n.edit}`}>
                {(params: any) => (
                  <RequireAuth>
                    <ParticipantEdit participant={unescape(params.name)} />
                  </RequireAuth>
                )}
              </Route>
              <Route path={`/${i18n.participant}/:name/:rest*`}>
                {(params: any) => <Redirect href={`/${i18n.participant}/${params.name}`} />}
              </Route>
              <Route path="/">
                <RequireAuth />
                <Overview />
              </Route>
              <Route path={`/:rest*`}>
                <Redirect href="/" />
              </Route>
            </Switch>
          )}
        </Container>
        {!isFlagHunt && (
          <footer class="mt-0 d-flex justify-content-center d-print-none">
            <small class="text-muted">
              Icon made by Freepik from <a href="https://www.flaticon.com">www.flaticon.com</a>
            </small>
          </footer>
        )}
      </div>
    </div>
  );
};

const RequireAuth: FunctionComponent = ({ children }) => {
  const token = useStore((s) => s.session.token);
  return token ? (
    <div>{toChildArray(children)}</div>
  ) : (
    <div class=" mt-5 alert alert-primary" role="alert">
      <h1>Log eerst in</h1>
      <p>Om iets te zien, moet je eerst inloggen. Vraag de leiding naar het wachtwoord.</p>
      <p>
        <Password />
      </p>
    </div>
  );
};
