import { h, FunctionalComponent, Fragment } from "preact";
import { Flag, FlagTeam } from "../../shared";
import { DashboardTeamCapture } from "./DashboardTeamCapture";
import { TeamStats } from "./TeamStats";
import { useMemo } from "preact/hooks";
import { Card } from "./Card";
import { Rank } from "./Rank";
import { safeColor } from "./utils";

export const DashboardTeam: FunctionalComponent<{
  team: TeamStats;
  toggleExpand: (team: FlagTeam) => void;
  expand: boolean;
  focusFlag: (flag: Flag) => void;
}> = ({ team, toggleExpand, expand, focusFlag }) => {
  const colors = useMemo(() => {
    return [
      safeColor(team.team.color).darken(0.5).string(),
      safeColor(team.team.color).darken(0.5).alpha(0.6).string(),
      safeColor(team.team.color).lighten(0.8).string(),
    ];
  }, [team.team.color]);

  return (
    <Card direction="vertical" style={{ position: "relative", backgroundColor: colors[2] }}>
      <div class={`team ${expand ? " expanded" : ""}`}>
        <div class="toggle-button team-title" style={{ color: colors[0] }} onClick={() => toggleExpand(team.team)}>
          <span class="team-name"> {team.team.name}</span>
          <span class="team-points">{team.score} 💰</span>
          <span class="team-rank">
            <Rank rank={team.rank} />
          </span>
        </div>
        {expand && (
          <Fragment>
            <div class="logo-and-members">
              <span class="logo" style={{ borderColor: colors[1] }}>
                {team.team.symbol}
              </span>
              {team.team.members.join(", ")}
            </div>
            <hr />
            <div class="captures">
              {team.flags.map((flag) => (
                <DashboardTeamCapture flag={flag} focusFlag={focusFlag} />
              ))}
            </div>
            {!team.flags.length && <div>Geen vlaggen</div>}
          </Fragment>
        )}
      </div>
    </Card>
  );
};
