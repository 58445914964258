import { FunctionComponent, Fragment, h, JSX } from "preact";
import { useStore } from "./store/store";

export const Alerts: FunctionComponent<JSX.HTMLAttributes> = ({ ...attrs }) => {
  const alerts = useStore((s) => s.alerts);
  return (
    <div {...attrs}>
      {alerts.map(({ title, text, severity }) => (
        <div class={`alert alert-${severity || "primary"}`} role="alert">
          {title && <h4 class="alert-heading">{title}</h4>}
          {text && <p>{text}</p>}
        </div>
      ))}
    </div>
  );
};
