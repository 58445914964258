import { FunctionComponent, Fragment, h } from "preact";
import { ParticipantRender } from "./Utilities";
import { Participant, Level, Data, Patrol } from "./store/types";
import { BadgeBar } from "./BadgeBar";
import { useStore } from "./storelib";
import { participantDetailsStore } from "./store/store";
import { useMemo } from "preact/hooks";
import { transformMostRecentPatrolYear } from "./store/transformations";

export const ParticipantHeader: FunctionComponent<{
  participant: Participant;
  levels?: Data["levels"];
}> = ({ participant, levels }) => {
  const details = useStore(participantDetailsStore, (s) => s && s[participant.key]);
  return (
    <Fragment>
      <div class="d-flex">
        <h1>
          <ParticipantRender patrol participant={participant} />
        </h1>
      </div>
      {levels && (
        <p class="lead">{details && <BadgeBar levels={levels} attainedLevelKey={details.attainedLevel.key} />}</p>
      )}
    </Fragment>
  );
};
