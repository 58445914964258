import { Dictionary } from "./store/types";

export function groupBy<T, TKey extends string = string>(items: T[], selector: (item: T) => TKey): Record<TKey, T[]> {
  const grouped: Record<TKey, T[]> = {} as any;
  items.forEach((i) => (grouped[selector(i)] ? grouped[selector(i)].push(i) : (grouped[selector(i)] = [i])));
  return grouped;
}
export function groupByIndex<T>(items: T[], selector: (item: T) => number): T[][] {
  const grouped: T[][] = [];
  items.forEach((i) => (grouped[selector(i)] ? grouped[selector(i)].push(i) : (grouped[selector(i)] = [i])));
  return grouped;
}

export function keyBy<T, TKey extends string = string>(items: T[], selector: (item: T) => TKey): Record<TKey, T> {
  const grouped: Record<TKey, T> = {} as any;
  items.forEach((i) => (grouped[selector(i)] = i));
  return grouped;
}

export function map<TFrom, TKey extends string = string>(items: Record<TKey, TFrom>) {
  return {
    to<TTo>(selector: (from: TFrom, key: TKey) => TTo): Record<TKey, TTo> {
      const grouped: Record<TKey, TTo> = {} as any;
      for (const key in items) {
        grouped[key] = selector(items[key], key);
      }
      return grouped;
    },
    values<TTo>(selector: (from: TFrom, key: TKey) => TTo): TTo[] {
      const values: TTo[] = [];
      for (const key in items) {
        values.push(selector(items[key], key));
      }
      return values;
    },
  };
}
export function mapd<TFrom, TKey extends string = string>(items: Dictionary<TKey, TFrom>) {
  return {
    to<TTo>(selector: (from: TFrom, key: TKey) => TTo): Dictionary<TKey, TTo> {
      const grouped: Record<TKey, TTo> = {} as any;
      for (const key in items) {
        grouped[key] = selector(items[key]!, key);
      }
      return grouped;
    },
    values<TTo>(selector: (from: TFrom, key: TKey) => TTo): TTo[] {
      const values: TTo[] = [];
      for (const key in items) {
        values.push(selector(items[key]!, key));
      }
      return values;
    },
  };
}
