import { h, FunctionalComponent, Fragment } from "preact";
import { Flag, FlagCapture, FlagTeam } from "../../shared";
import { Data, Dictionary } from "../store/types";
import { useCallback, useMemo, useState } from "preact/hooks";
import { Card } from "./Card";
import { DashboardTeam } from "./DashboardTeam";
import { TeamStats } from "./TeamStats";
import { last } from "./utils";
import { Icon } from "./Icon";

export const DashboardImpl: FunctionalComponent<{
  map: Data["map"];
  focusFlag: (flag: Flag) => void;
}> = ({ map, focusFlag }) => {
  const teams: TeamStats[] = useMemo(() => {
    const flags = map.flags.keys
      .map((f) => map.flags.keyed[f]!)
      .map((f) => {
        const captureKey = last(map.captures.byFlag[f.key]);
        return {
          flag: f,
          capture: !captureKey ? undefined : map.captures.keyed[captureKey]!,
        };
      });
    const flagsByTeam: Dictionary<string, { flag: Flag; capture?: FlagCapture }[]> = {};
    for (const flag of flags) {
      if (!flag.capture) {
        continue;
      }

      if (!flagsByTeam[flag.capture.teamKey]) {
        flagsByTeam[flag.capture.teamKey] = [];
      }

      flagsByTeam[flag.capture.teamKey]!.push(flag);
    }

    return map.teams.keys
      .map((t) => map.teams.keyed[t]!)
      .map((t) => ({
        team: t,
        flags: flagsByTeam[t.key] ?? [],
        score: flagsByTeam[t.key]?.reduce((a, b) => a + b.flag.points, 0) ?? 0,
      }))
      .sort((a, b) => b.score - a.score)
      .map((t, i) => ({
        ...t,
        rank: i,
      }));
  }, [map]);

  const [expand, setExpand] = useState<Dictionary<string, boolean>>({
    _default: false,
  });
  const [show, setShow] = useState(true);

  const toggleExpand = useCallback((team: FlagTeam) => {
    setExpand((e) => ({
      ...e,
      [team.key]: !(e[team.key] ?? e["_default"]),
    }));
  }, []);

  const toggleShow = useCallback(() => {
    setShow((s) => !s);
  }, [show]);

  const toggleAll = useCallback((show?: boolean) => {
    setExpand((e) => ({
      _default: show ?? !e._default,
    }));

    if (show) {
      setShow(true);
    }
  }, []);

  return (
    <div class="dashboard">
      <Card direction="vertical" class="expansion">
        <span class="toggle-button" title="Open" onClick={() => toggleShow()}>
          <Icon icon={show ? "toggle2-on" : "toggle2-off"} />
        </span>
        {show && (
          <Fragment>
            <span class="toggle-button" title="Open team details" onClick={() => toggleAll(true)}>
              <Icon icon="chevron-bar-expand" />
            </span>
            <span class="toggle-button" title="Verberg team details" onClick={() => toggleAll(false)}>
              <Icon icon="chevron-bar-contract" />
            </span>
          </Fragment>
        )}
      </Card>
      <div class={`teams ${show ? "shown" : "hidden"}`}>
        {teams.map((team) => (
          <DashboardTeam
            toggleExpand={toggleExpand}
            expand={expand[team.team.key] ?? !!expand["_default"]}
            team={team}
            focusFlag={focusFlag}
          />
        ))}
      </div>
    </div>
  );
};
