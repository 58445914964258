export function join<T>(separator: T, lastSeparator: T, elements: T[]) {
  const result: T[] = [];
  if (elements.length >= 1) {
    result.push(elements[0]);
  }
  for (var i = 1; i < elements.length - 1; i++) {
    result.push(separator, elements[i]);
  }
  if (elements.length >= 2) {
    result.push(lastSeparator, elements[elements.length - 1]);
  }
  return result;
}

export function lookup<TKey extends keyof any, TValue>(items: Record<TKey, TValue>): (key: TKey) => TValue {
  return (key) => items[key];
}
export function flatten<T>(items: T[][]): T[] {
  return ([] as T[]).concat(...items);
}
