import { Store, createReducer, createSubStore } from "./storelib";
import { createSubscribers, Subscribable } from "../shared/subscribers";

export interface PersisterDef<TStored> {
  key: string;
  parse?(stored: string): TStored;
  stringify?(stored: TStored): string;
}
export interface AccessorDef<TState, TStored> {
  getter(state: TState): TStored;
  setter(state: TState, stored: TStored): TState;
  shouldPersist?(state: TState): boolean;
}
export interface Persister<TStored> {
  value: TStored;
  readonly onChange: Subscribable<(stored: TStored) => void>["addSubscriber"];
}
export function createPersister<TStored>(def: PersisterDef<TStored>): Persister<TStored> {
  var { key, parse, stringify } = {
    parse: JSON.parse,
    stringify: JSON.stringify,
    ...def,
  };

  type ValueType = TStored;

  var subs = createSubscribers<(stored: ValueType) => void>();

  window.addEventListener("storage", (ev) => {
    if (ev.storageArea != localStorage || ev.key != key) return;

    const stored: TStored = ev.newValue === null ? undefined : parse(ev.newValue);
    subs.invoke(stored);
  });

  return {
    get value(): ValueType {
      let stringValue = localStorage.getItem(def.key);
      return stringValue === null ? undefined : parse(stringValue);
    },
    set value(stored: ValueType) {
      stored === undefined ? localStorage.removeItem(key) : localStorage.setItem(key, stringify(stored));
    },
    get onChange() {
      return subs.addSubscriber;
    },
  };
}

export function createStorePersistence<TState, TStored>(
  store: Store<TState>,
  persisterOrDef: PersisterDef<TStored> | Persister<TStored>,
  options: AccessorDef<TState, TStored>
) {
  var { getter, setter, shouldPersist } = {
    shouldPersist: () => true,
    ...options,
  };

  const storedStore = createSubStore(store, getter, setter);
  const shouldPersistReducer = createReducer(store, shouldPersist);
  const persister = ensurePersister<TStored>(persisterOrDef);

  let currentStored = storedStore.getState();

  storedStore.subscribe((stored) => {
    if (!shouldPersistReducer.getState()) return;
    if (currentStored == stored) return;

    currentStored = stored;
    persister.value = stored;
  });

  persister.onChange((stored) => {
    currentStored = stored;
    storedStore.setState(stored);
  });
}

function ensurePersister<TStored>(persister: PersisterDef<TStored> | Persister<TStored>) {
  return "key" in persister ? createPersister(persister) : persister;
}
