import { Dictionary } from "../store/types";
import { FlagTeam } from "../../shared";
import { safeColor } from "./utils";
import { useStore } from "../store/store";
import { useMemo } from "preact/hooks";

const areaBounds: google.maps.LatLngLiteral[] = [
  { lat: 51.986966, lng: 5.821617 },
  { lat: 51.988176, lng: 5.821986 },
  { lat: 51.989171, lng: 5.821886 },
  { lat: 51.989876, lng: 5.822015 },
  { lat: 51.99264, lng: 5.823683 },
  { lat: 51.994771, lng: 5.82645 },
  { lat: 51.996277, lng: 5.828624 },
  { lat: 51.997686, lng: 5.830327 },
  { lat: 51.998866, lng: 5.826262 },
  { lat: 51.999602, lng: 5.821885 },
  { lat: 52.000042, lng: 5.819388 },
  { lat: 52.000271, lng: 5.819016 },
  { lat: 52.001328, lng: 5.812922 },
  { lat: 52.002156, lng: 5.809518 },
  { lat: 52.002737, lng: 5.805555 },
  { lat: 52.000958, lng: 5.802451 },
  { lat: 51.99976, lng: 5.800219 },
  { lat: 51.998809, lng: 5.797902 },
  { lat: 51.9974, lng: 5.799104 },
  { lat: 51.995797, lng: 5.800677 },
  { lat: 51.995183, lng: 5.801346 },
  { lat: 51.994526, lng: 5.802662 },
  { lat: 51.994993, lng: 5.803656 },
  { lat: 51.994048, lng: 5.805136 },
  { lat: 51.993268, lng: 5.806574 },
  { lat: 51.99197, lng: 5.809649 },
  { lat: 51.991221, lng: 5.810566 },
  { lat: 51.990122, lng: 5.812036 },
  { lat: 51.989563, lng: 5.812744 },
  { lat: 51.989371, lng: 5.813214 },
  { lat: 51.989402, lng: 5.815754 },
  { lat: 51.988251, lng: 5.81672 },
  { lat: 51.988127, lng: 5.817521 },
];

export interface FlagHuntState {
  bounds: google.maps.LatLngBoundsLiteral;
  center: google.maps.LatLngLiteral;
}

export function useFlagHunt(): FlagHuntState | undefined {
  const flagHuntConfig = useStore((s) => s.flagHunt);
  return useMemo(() => {
    if (!flagHuntConfig) {
      return undefined;
    }
    const bounds: google.maps.LatLngBoundsLiteral = flagHuntConfig?.bounds
      .map((c) => ({
        west: c.lng,
        east: c.lng,
        north: c.lat,
        south: c.lat,
      }))
      .reduce((a, b) => ({
        west: Math.min(a.west, b.west),
        east: Math.max(a.east, b.east),
        north: Math.max(a.north, b.north),
        south: Math.min(a.south, b.south),
      }));

    const center: google.maps.LatLngLiteral = {
      lat: (bounds.north + bounds.south) / 2,
      lng: (bounds.west + bounds.east) / 2,
    };

    const lngDelta = bounds.east - bounds.west;
    const latDelta = bounds.north - bounds.south;
    bounds.east += lngDelta * 0.5;
    bounds.west -= lngDelta * 0.5;
    bounds.north += latDelta * 0.5;
    bounds.south -= latDelta * 0.5;

    return {
      bounds,
      center,
    };
  }, [flagHuntConfig?.bounds]);
}

const markerCache: Dictionary<string, string> = {};
export function createMarker(team: FlagTeam | undefined, radius: number, strokeWidth: number): string {
  const color = team?.color ?? "rgb(150, 150, 150)";
  if (color in markerCache) {
    return markerCache[color]!;
  }

  var canvas, context;

  canvas = document.createElement("canvas");
  canvas.width = radius * 2;
  canvas.height = radius * 2;

  context = canvas.getContext("2d")!;

  context.clearRect(0, 0, radius * 2, radius * 2);

  // background is yellow
  context.fillStyle = safeColor(color).lighten(0.6).alpha(0.5).string();

  // border is black
  context.strokeStyle = safeColor(color).darken(0.5).alpha(0.6).string();
  context.lineWidth = strokeWidth;

  context.beginPath();
  context.arc(radius, radius, radius - strokeWidth * 0.5, 0, 2 * Math.PI);
  context.closePath();

  context.fill();
  context.stroke();

  context.fillStyle = "transparent";

  // border is black
  context.strokeStyle = safeColor(color).alpha(0.6).string();
  context.lineWidth = strokeWidth;

  context.beginPath();
  context.arc(radius, radius, radius - strokeWidth * 1.5, 0, 2 * Math.PI);
  context.closePath();

  context.fill();
  context.stroke();

  return (markerCache[color] = canvas.toDataURL());
}
