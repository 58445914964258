import { FunctionComponent, Fragment, h } from "preact";
import { useStore, participantDetailsStore } from "./store/store";
import { useStore as useStoreLib } from "./storelib";
import { useMemo, useState, useEffect } from "preact/hooks";
import { SkillLink, ParticipantRender } from "./Utilities";
import { join, flatten } from "./utils";
import { groupByIndex } from "./functional";
import { Rank } from "./Rank";
import { Data, Skill } from "./store/types";
import { Title } from "./Title";
import { Link } from "wouter-preact";
import * as i18n from "./i18n";
import { Badge, starImages } from "./BadgeBar";
import { ProgressRank } from "./store/transformations";
import { Tm } from "./store/i18l";

export const GroupOverview: FunctionComponent = () => {
  const data = useStore((s) => s.data);
  const showStars = useStore((s) => s.features.stars);
  const desc = false;
  const [detailed, setDetailed] = useState(false);
  const listing = useMemo(() => {
    if (!data) {
      return false;
    }

    const participants = data.participants.scoutKeys.map((pKey) => {
      var participantSkills = data.progress.byParticipantThenSkill[pKey] || {};
      const lowestRankWithUnfinishedSkills = data.skills.byRank.findIndex((skills) =>
        skills.some((skill) => !participantSkills[skill.key] || !participantSkills[skill.key].done)
      );
      var lowestRank =
        lowestRankWithUnfinishedSkills == -1
          ? data.levels.byRank
              .slice()
              .reverse()
              .find((l) => data.skills.byRank[l.rank].length)!.rank + 1
          : Math.max(1, lowestRankWithUnfinishedSkills);
      var skillsToDo = data.skills.byRank[lowestRank].filter(
        (s) => !participantSkills[s.key].doing && !participantSkills[s.key].done
      );
      var skillsDoing = data.skills.byRank[lowestRank].filter(
        (s) => participantSkills[s.key].doing && !participantSkills[s.key].done
      );
      return [pKey, lowestRank - 1, skillsToDo, skillsDoing] as const;
    });
    const sortedParticipants = participants.sort(
      desc
        ? ([, , l], [, , r]) => r.length - l.length
        : ([, , todoLeft, doingLeft], [, , todoRight, doingRight]) =>
            todoLeft.length * 1.0001 + doingLeft.length - (todoRight.length * 1.0001 + doingRight.length)
    );
    return groupByIndex(sortedParticipants, ([, rank]) => rank);
  }, [data]);

  useEffect(() => setDetailed(true), []);

  return !listing || !data ? (
    <Fragment>Loading</Fragment>
  ) : (
    <Fragment>
      <Title>Overzicht</Title>
      <h1>Overzicht</h1>
      <p class="lead">
        <Tm prop="GroupOverview-Intro" />
      </p>
      <p>Technieken met een * ben je al aan begonnen</p>
      {listing.map((rankListing, rank) => (
        <div class="mt-4">
          <h2>
            <Badge
              level={data.levels.byRank[rank]}
              postfix={<small class="ms-2 badge rounded-pill bg-light text-dark">{rankListing.length}</small>}
            />
          </h2>
          <div class="sc-group-overview">
            {rankListing.map(([pKey, currentRank, todo, doing]) => (
              <CardWrapper
                data={data}
                pKey={pKey}
                todo={todo}
                doing={doing}
                currentRank={currentRank}
                stars={showStars}
                detailed={detailed}
              />
            ))}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

const CardWrapper: FunctionComponent<{
  data: Data;
  pKey: string;
  todo: Skill[];
  doing: Skill[];
  currentRank: number;
  stars?: boolean;
  detailed?: boolean;
}> = ({ detailed, ...props }) => {
  const [showDetailed, setShowDetailed] = useState(false);
  return (
    <div class={`sc-scout-card ${showDetailed ? "show-detailed" : ""}`} onClick={() => setShowDetailed(!showDetailed)}>
      {detailed && <Card class="detailed" {...props} />}
      <Card class="short" {...props} skillLimit={3} />
    </div>
  );
};

const Card: FunctionComponent<{
  data: Data;
  pKey: string;
  todo: Skill[];
  doing: Skill[];
  currentRank: number;
  skillLimit?: number;
  class?: string;
  stars?: boolean;
}> = ({ data, pKey, todo, doing, currentRank, skillLimit, class: c, stars }) => {
  const skills = [
    ...doing.map((skill) => (
      <SkillLink skill={skill} participant={data.participants.keyed[pKey]} title="begonnen">
        {skill.name}*
      </SkillLink>
    )),
    ...todo.map((skill) => (
      <SkillLink skill={skill} participant={data.participants.keyed[pKey]} title="nog niet begonnen" />
    )),
  ];
  const details = useStoreLib(participantDetailsStore, (s) => s && s[pKey]);
  if (skillLimit === undefined) {
    skillLimit = 0xffffff;
  }
  return (
    <div class={`sc-card border rounded-sm ${c}`}>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <ParticipantRender link patrol participant={data.participants.keyed[pKey]} />
        </div>{" "}
        {skills.length ? (
          <Link href={`/${i18n.participant}/${pKey}/${i18n.progress}`}>
            <a>
              <span>
                {skills.length} tot <Badge level={data.levels.byRank[currentRank + 1]} />
              </span>
            </a>
          </Link>
        ) : (
          <Fragment>Klaar</Fragment>
        )}
      </div>
      <div>
        {stars &&
          details &&
          flatten(
            Object.values(details.byCategory).map((c) =>
              flatten(c.byLevel.map((l) => l.skills.map((s) => s.progressRank)))
            )
          )
            .filter((p) => p != ProgressRank.todo)
            .sort((a, b) => b - a)
            .map((p) =>
              p == ProgressRank.todo
                ? starImages.emptyYellow
                : p == ProgressRank.done
                ? starImages.fullYellow
                : starImages.halfYellow
            )}
      </div>
      <div class="sc-card-details border-top mt-2 pt-1">
        {join(
          <Fragment>, </Fragment>,
          <Fragment>{skills.length > 3 ? ", " : " en "}</Fragment>,
          skills.slice(0, skillLimit)
        )}
        {skills.length > skillLimit ? (
          <Fragment>
            {" "}
            en{" "}
            <ParticipantRender participant={data.participants.keyed[pKey]}>{skills.length - 3} meer</ParticipantRender>
          </Fragment>
        ) : (
          false
        )}
      </div>
    </div>
  );
};
