import { store } from "./store/store";
import { createEventerClient, createEventerWebSocketPipe, EventerClient } from "./eventerClient";
import { Data, Progress, Patrol, Participant } from "./store/types";
import {
  Event,
  FlagCaptureRemove,
  FlagCaptureUpsert,
  FlagRemove,
  FlagTeamRemove,
  FlagTeamUpsert,
  FlagUpsert,
  Initial,
  ParticipantCreate,
  ParticipantPatch,
  ParticipantRemove,
} from "../shared";
import produce from "immer";

export async function createBoundEventer(
  host: string,
  onConnectStatus?: (connected: boolean) => void
): Promise<EventerClient<Event, Data>["queue"]> {
  // const pipe = await createEventerWebSocketPipe<Event, Initial>(`${host}/ws/`.replace(/https:/, 'wss:').replace(/http:/, 'ws:'))
  const pipe = await createEventerWebSocketPipe<Event, Initial>(`${host}/sockjs/`, "sockjs");

  if (onConnectStatus) {
    pipe.onConnectStatus(onConnectStatus);
  }

  const eventerClient = createEventerClient<Event, Data, Initial>(init, update, pipe);
  eventerClient.addStateChangeListener((data) => store.update((s) => ({ ...s, data })));
  pipe.setToken(store.getState().session.token ? store.getState().session.token?.token : undefined, true);
  store.subscribe((s) => pipe.setToken(s.session.token ? s.session.token.token : undefined, true));

  return eventerClient.queue;
}

function init(initial: Initial): Data {
  const { participants, skills, categories, progress, levels, guides, patrols, patrolMemberships, map } = initial;
  var data: Data = {
    categories: {
      keys: [],
      keyed: {},
    },
    participants: {
      keys: [],
      keyed: {},
      scoutKeys: [],
      guideKeys: [],
    },
    skills: {
      keys: [],
      keyed: {},
      byCategory: {},
      byRank: [],
    },
    guides: {
      byCategory: {},
      byGuide: {},
    },
    levels: {
      keys: [],
      keyed: {},
      byRank: [],
    },
    progress: {
      byParticipantThenSkill: {},
      bySkill: {},
      chrono: [],
    },
    patrols: {
      byParticipant: {},
      byParticipantByYear: {},
      byYear: {},
      years: [],
      keyed: {},
      keys: [],
    },
    map: {
      flags: {
        keyed: {},
        keys: [],
      },
      teams: {
        keyed: {},
        keys: [],
      },
      captures: {
        byFlag: {},
        byTeam: {},
        keyed: {},
        keys: [],
      },
    },
  };

  map.teams.forEach((t) => {
    data.map.teams.keys.push(t.key);
    data.map.teams.keyed[t.key] = t;
  });

  map.flags.forEach((f) => {
    data.map.flags.keys.push(f.key);
    data.map.flags.keyed[f.key] = f;
  });

  map.captures.forEach((c) => {
    data.map.captures.keys.push(c.key);
    data.map.captures.keyed[c.key] = c;
    upsert(data.map.captures.byFlag, c.flagKey, c.key);
    upsert(data.map.captures.byTeam, c.teamKey, c.key);
  });

  for (const captures of Object.values(data.map.captures.byFlag)) {
    captures?.sort((ak, bk) => {
      const aw = data.map.captures.keyed[ak]!.when;
      const bw = data.map.captures.keyed[bk]!.when;
      return aw > bw ? 1 : aw < bw ? -1 : 0;
    });
  }

  for (const captures of Object.values(data.map.captures.byTeam)) {
    captures?.sort((ak, bk) => {
      const aw = data.map.captures.keyed[ak]!.when;
      const bw = data.map.captures.keyed[bk]!.when;
      return aw > bw ? 1 : aw < bw ? -1 : 0;
    });
  }

  data.map.captures.keys?.sort((ak, bk) => {
    const aw = data.map.captures.keyed[ak]!.when;
    const bw = data.map.captures.keyed[bk]!.when;
    return aw > bw ? 1 : aw < bw ? -1 : 0;
  });

  levels.forEach((l) => {
    data.levels.keys.push(l.key);
    data.levels.keyed[l.key] = l;
    data.levels.byRank[l.rank] = l;
    data.skills.byRank[l.rank] = [];
  });

  participants.forEach((p) => {
    data.participants.keys.push(p.key);
    if (p.scout) data.participants.scoutKeys.push(p.key);
    if (p.guide) data.participants.guideKeys.push(p.key);
    data.participants.keyed[p.key] = withFullName(p);
    const progress: typeof data.progress.byParticipantThenSkill[""] = (data.progress.byParticipantThenSkill[p.key] =
      {});
    skills.forEach((s) => {
      progress[s.key] = {};
    });
    data.patrols.byParticipant[p.key] = [];
    data.patrols.byParticipantByYear[p.key] = {};
  });

  patrols.forEach((p) => {
    const patrol: Patrol = {
      key: p.key,
      name: p.name,
      colors: p.colors,
      icon: p.icon,
      years: [],
    };
    data.patrols.keyed[p.key] = patrol;
  });
  patrolMemberships.forEach((pm) => {
    const patrol = data.patrols.keyed[pm.patrol];
    if (!patrol) {
      console.error("membership found without patrol", pm);
      return;
    }

    let year = patrol.years.find((p) => p.year == pm.year);
    if (!year) {
      patrol.years.push(
        (year = {
          year: pm.year,
          members: [],
          pl: undefined,
          apl: undefined,
        })
      );
      data.patrols.byYear[pm.year] = push(data.patrols.byYear[pm.year], patrol);
    }

    if (pm.role && pm.role in year) {
      year[pm.role] = pm.participant;
    }

    year.members.push(pm.participant);
    data.patrols.byParticipant[pm.participant].push(patrol);
    data.patrols.byParticipantByYear[pm.participant][pm.year] = patrol;
  });

  data.patrols.years = Object.keys(data.patrols.byYear).map((y) => +y);
  data.patrols.keys = Object.keys(data.patrols.keyed);

  skills.forEach((s) => {
    data.skills.keys.push(s.key);
    data.skills.keyed[s.key] = s;
    data.skills.byCategory[s.category] = push(data.skills.byCategory[s.category], s);
    data.skills.byRank[data.levels.keyed[s.level].rank].push(s);
  });

  categories.forEach((c) => {
    data.categories.keys.push(c.key);
    data.categories.keyed[c.key] = c;
  });

  guides.forEach((g) => {
    data.guides.byCategory[g.category] = push(data.guides.byCategory[g.category], g);
    data.guides.byGuide[g.guide] = push(data.guides.byGuide[g.guide], g);
  });

  progress.forEach((p) => {
    const storeP = {
      ...p,
      when: new Date(p.when),
    };
    data.progress.byParticipantThenSkill[p.participant][p.skill][p.progress] = storeP;
    data.progress.bySkill[p.skill] = push(data.progress.bySkill[p.skill], storeP);
    data.progress.chrono.push(storeP);
  });
  data.progress.chrono.sort((l, r) => +r.when - +l.when);
  return data;

  function push<T>(arr: T[] | undefined, val: T): T[] {
    if (!arr) arr = [];
    arr.push(val);
    return arr;
  }
  function update<T>(
    rec: Record<string, T> | undefined,
    key: string,
    val: (val: T | undefined) => T
  ): Record<string, T> {
    if (!rec) rec = {};
    rec[key] = val(rec[key]);
    return rec;
  }
}

function update(data: Data, event: Event): Data {
  switch (event.type) {
    case "progress-update":
      return setSkillProgress(event.participant, event.skill, event.guide, event.progress, event.action)(data);
    case "participant-create":
      return setParticipantCreate(event)(data);
    case "participant-remove":
      return setParticipantRemove(event)(data);
    case "participant-patch":
      return setParticipantPatch(event)(data);
    case "flag-team-upsert":
      return applyFlagTeamUpsert(event)(data);
    case "flag-team-remove":
      return applyFlagTeamRemove(event)(data);
    case "flag-capture-upsert":
      return applyFlagCaptureUpsert(event)(data);
    case "flag-capture-remove":
      return applyFlagCaptureRemove(event)(data);
    case "flag-upsert":
      return applyFlagUpsert(event)(data);
    case "flag-remove":
      return applyFlagRemove(event)(data);
  }
  console.warn(`unknown event type ${(event as any)?.type}`);
  return data;
}

function setSkillProgress(
  participant: string,
  skill: string,
  guide: string,
  progress: "doing" | "done",
  action: "confirm" | "retract"
) {
  function strip(pair: { doing?: Progress; done?: Progress } | undefined): {
    doing?: Progress;
    done?: Progress;
  } {
    const { [progress]: removed, ...stripped } = pair || {};
    return stripped;
  }
  function confirm(progressData: Data["progress"]): Data["progress"] {
    const progressObject: Progress = {
      guide,
      participant,
      progress,
      skill,
      when: new Date(),
    };
    return {
      chrono: [progressObject, ...progressData.chrono],
      bySkill: {
        ...progressData.bySkill,
        [skill]: [progressObject, ...(progressData.bySkill[skill] || [])],
      },
      byParticipantThenSkill: {
        ...progressData.byParticipantThenSkill,
        [participant]: {
          ...progressData.byParticipantThenSkill[participant],
          [skill]: {
            ...(progressData.byParticipantThenSkill[participant] || {})[skill],
            [progress]: progressObject,
          },
        },
      },
    };
  }
  function retract(progressData: Data["progress"]): Data["progress"] {
    function filter(p: Progress) {
      return !(p.participant == participant && p.skill == skill && p.progress == progress);
    }
    return {
      chrono: progressData.chrono.filter(filter),
      byParticipantThenSkill: {
        ...progressData.byParticipantThenSkill,
        [participant]: {
          ...progressData.byParticipantThenSkill[participant],
          [skill]: strip((progressData.byParticipantThenSkill[participant] || {})[skill]),
        },
      },
      bySkill: {
        ...progressData.bySkill,
        [skill]: (progressData.bySkill[skill] || []).filter(filter),
      },
    };
  }
  return (data: Data) =>
    <Data>{
      ...data,
      progress: action == "confirm" ? confirm(data.progress) : retract(data.progress),
    };
}

function setParticipantCreate(event: ParticipantCreate) {
  event.patrols?.forEach((patrol) => {});
  return (data: Data) =>
    <Data>{
      ...data,
    };
}

function setParticipantRemove(event: ParticipantRemove) {
  return (data: Data) =>
    <Data>{
      ...data,
    };
}

function setParticipantPatch(event: ParticipantPatch) {
  const { type, key, ...p } = event;
  return (data: Data) => {
    const prevP = data.participants.keyed[event.key];
    let patrols: Data["patrols"] = data.patrols;
    if (event.patrols) {
      patrols = produce(data.patrols, (patrols) => {
        patrols.byParticipant[key].forEach((patrol) => {
          patrol.years.forEach((y) => {
            patrols.byYear[y.year] = patrols.byYear[y.year]?.filter((p) => p != patrol);
            y.members = y.members.filter((m) => m != key);
            if (y.apl == key) {
              y.apl = undefined;
            }
            if (y.pl == key) {
              y.pl = undefined;
            }
          });
        });

        var byParticipant = event.patrols!.map((p) => data.patrols.keyed[p.patrol]!);
        var byParticipantByYear: Record<number, Patrol> = {};
        byParticipant.forEach((p) => {
          p.years.forEach((y) => {
            byParticipantByYear[y.year] = p;
            patrols.byYear[y.year] = [...(patrols.byYear[y.year] ?? []), p];
            y.members.push(key);
          });
        });
        patrols.byParticipant[key] = byParticipant;
        patrols.byParticipantByYear[key] = byParticipantByYear;
      });
    }
    return <Data>{
      ...data,
      patrols,
      participants: {
        keyed: {
          ...data.participants.keyed,
          [event.key]: withFullName({
            ...prevP,
            ...p,
          }),
        },
        keys: data.participants.keys,
        guideKeys:
          !("guide" in p) || prevP.guide == p.guide
            ? data.participants.guideKeys
            : p.guide
            ? [...data.participants.guideKeys, prevP.key]
            : data.participants.guideKeys.filter((g) => g != prevP.key),
        scoutKeys:
          !("scout" in p) || prevP.scout == p.scout
            ? data.participants.scoutKeys
            : p.scout
            ? [...data.participants.scoutKeys, prevP.key]
            : data.participants.scoutKeys.filter((g) => g != prevP.key),
      },
    };
  };
}
function applyFlagTeamUpsert(event: FlagTeamUpsert) {
  return (data: Data) => {
    return produce(data, (data) => {
      if (event.team.key in data.map.teams.keyed) {
        data.map.teams.keyed[event.team.key] = event.team;
      } else {
        data.map.teams.keys.push(event.team.key);
        data.map.teams.keyed[event.team.key] = event.team;
      }
    });
  };
}
function applyFlagTeamRemove(event: FlagTeamRemove) {
  return (data: Data) => {
    return produce(data, (data) => {
      delete data.map.teams.keyed[event.teamKey];
      data.map.teams.keys = data.map.teams.keys.filter((t) => t != event.teamKey);
      for (const captureKey of data.map.captures.byTeam[event.teamKey] ?? []) {
        for (const flagKey of Object.keys(data.map.captures.byFlag)) {
          data.map.captures.byFlag[flagKey] = data.map.captures.byFlag[flagKey]?.filter((k) => k != captureKey);
        }
        delete data.map.captures.byTeam[event.teamKey];
        data.map.captures.keys = data.map.captures.keys.filter((k) => k != captureKey);
        delete data.map.captures.keyed[captureKey];
      }
    });
  };
}
function applyFlagCaptureUpsert(event: FlagCaptureUpsert) {
  return (data: Data) => {
    return produce(data, (data) => {
      if (event.capture.key in data.map.captures.keyed) {
        const current = data.map.captures.keyed[event.capture.key]!;
        data.map.captures.keyed[event.capture.key] = event.capture;

        data.map.captures.keys?.sort((ak, bk) => {
          const aw = data.map.captures.keyed[ak]!.when;
          const bw = data.map.captures.keyed[bk]!.when;
          return aw > bw ? 1 : aw < bw ? -1 : 0;
        });

        if (current.teamKey != event.capture.teamKey) {
          data.map.captures.byTeam[current.teamKey] = data.map.captures.byTeam[current.teamKey]?.filter(
            (t) => t != event.capture.key
          );
          upsert(data.map.captures.byTeam, event.capture.teamKey, event.capture.key);
        }

        if (current.flagKey != event.capture.flagKey) {
          data.map.captures.byFlag[current.flagKey] = data.map.captures.byFlag[current.flagKey]?.filter(
            (t) => t != event.capture.key
          );
          upsert(data.map.captures.byFlag, event.capture.flagKey, event.capture.key);
        }
      } else {
        upsert(data.map.captures.byTeam, event.capture.teamKey, event.capture.key);
        upsert(data.map.captures.byFlag, event.capture.flagKey, event.capture.key);
        data.map.captures.keyed[event.capture.key] = event.capture;
        data.map.captures.keys.push(event.capture.key);
      }

      data.map.captures.byTeam[event.capture.teamKey]!.sort((ak, bk) => {
        const aw = data.map.captures.keyed[ak]!.when;
        const bw = data.map.captures.keyed[bk]!.when;
        return aw > bw ? 1 : aw < bw ? -1 : 0;
      });

      data.map.captures.byFlag[event.capture.flagKey]!.sort((ak, bk) => {
        const aw = data.map.captures.keyed[ak]!.when;
        const bw = data.map.captures.keyed[bk]!.when;
        return aw > bw ? 1 : aw < bw ? -1 : 0;
      });

      data.map.captures.keys?.sort((ak, bk) => {
        const aw = data.map.captures.keyed[ak]!.when;
        const bw = data.map.captures.keyed[bk]!.when;
        return aw > bw ? 1 : aw < bw ? -1 : 0;
      });
    });
  };
}
function applyFlagCaptureRemove(event: FlagCaptureRemove) {
  return (data: Data) => {
    return produce(data, (data) => {
      const capture = data.map.captures.keyed[event.captureKey]!;

      data.map.captures.byTeam[capture.teamKey] = data.map.captures.byTeam[capture.teamKey]?.filter(
        (c) => c != event.captureKey
      );
      data.map.captures.byFlag[capture.flagKey] = data.map.captures.byFlag[capture.flagKey]?.filter(
        (c) => c != event.captureKey
      );
      data.map.captures.keys = data.map.captures.keys.filter((c) => c != event.captureKey);

      delete data.map.captures.keyed[event.captureKey];
    });
  };
}
function applyFlagUpsert(event: FlagUpsert) {
  return (data: Data) => {
    return produce(data, (data) => {
      if (event.flag.key in data.map.flags.keyed) {
        data.map.flags.keyed[event.flag.key] = event.flag;
      } else {
        data.map.flags.keys.push(event.flag.key);
        data.map.flags.keyed[event.flag.key] = event.flag;
      }
    });
  };
}
function applyFlagRemove(event: FlagRemove) {
  return (data: Data) => {
    return produce(data, (data) => {
      delete data.map.flags.keyed[event.flagKey];
      data.map.flags.keys = data.map.flags.keys.filter((t) => t != event.flagKey);
    });
  };
}

function withFullName(p: Omit<Participant, "fullName">): Participant {
  return {
    ...p,
    fullName: p.lastNamePrefix ? `${p.firstName} ${p.lastNamePrefix} ${p.lastName}` : `${p.firstName} ${p.lastName}`,
  };
}

function upsert<T, TKey extends string>(owner: { [key in TKey]?: T[] }, key: TKey, value: T) {
  if (!(key in owner)) {
    owner[key] = [];
  }

  owner[key]!.push(value);
}
