import { Fragment, FunctionalComponent, FunctionComponent, h } from "preact";
import { useStore } from "./store/store";
import { Data, Participant, Patrol } from "./store/types";
import { Redirect } from "wouter-preact";
import { useParticipantDetails } from "./store/participantDetails";
import { Title } from "./Title";
import { ParticipantHeader } from "./ParticipantHeader";
import { useCallback, useDebugValue, useEffect, useMemo, useRef, useState } from "preact/hooks";
import { getEventer } from "./singleton";
import { ParticipantPatch } from "../shared";
import { participant } from "./i18n";

const ParticipantEditImpl: FunctionComponent<{
  participant: string;
  data: Data;
}> = ({ participant, data }) => {
  const details = useParticipantDetails(participant);

  if (!details) {
    return <div>Loading</div>;
  }

  const p = details.participant;

  return (
    <div>
      <Title>Details van {p.fullName}</Title>
      <ParticipantHeader participant={p} />
      <ParticipantDetailsEdit participant={p} />
      <h3>Patrouilles</h3>
      <ParticipantPatrolsEdit participant={p} patrols={data.patrols} />
    </div>
  );
};

const ParticipantDetailsEdit: FunctionComponent<{ participant: Participant }> = ({ participant }) => {
  const p = participant;
  const submitSingleField = useCallback(
    async function (value: any, field: string) {
      const patch: ParticipantPatch = {
        type: "participant-patch",
        key: p.key,
        [field]: value,
      };

      if (field == "lastNamePrefix") {
        patch.lastNamePrefix = (patch.lastNamePrefix && patch.lastNamePrefix.trim()) || null;
      }

      getEventer()!(patch);
    },
    [p.key]
  );

  var firstName = useFormHandler("text", "firstName", p.firstName, submitSingleField);
  var lastNamePrefix = useFormHandler("text", "lastNamePrefix", p.lastNamePrefix || "", submitSingleField);
  var lastName = useFormHandler("text", "lastName", p.lastName, submitSingleField);
  var scout = useFormHandler("checkbox", "scout", p.scout, submitSingleField);
  var guide = useFormHandler("checkbox", "guide", p.guide, submitSingleField);

  return (
    <form>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="firstName">Voornaam</label>
          <input class="form-control" {...firstName.props} />
        </div>
        <div class="form-group col-md-4">
          <label for="lastNamePrefix">Tussenvoegsel</label>
          <input class="form-control" {...lastNamePrefix.props} />
        </div>
        <div class="form-group col-md-4">
          <label for="lastName">Achternaam</label>
          <input class="form-control" {...lastName.props} />
        </div>
      </div>
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input class="form-check-input" {...scout.props} />
          <label class="form-check-label" for="scout">
            Scout
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" {...guide.props} />
          <label class="form-check-label" for="guide">
            Leiding
          </label>
        </div>
      </div>
    </form>
  );
};

const ParticipantPatrolsEdit: FunctionComponent<{
  participant: Participant;
  patrols: Data["patrols"];
}> = ({ participant, patrols }) => {
  var patrolYears = Object.keys(patrols.byParticipantByYear[participant.key])
    .map((year) => +year)
    .sort();
  return (
    <div>
      {patrolYears.map((year) => (
        <ParticipantPatrolEdit participant={participant} year={year} patrols={patrols} />
      ))}
      <ParticipantPatrolEdit participant={participant} year={undefined} patrols={patrols} />
    </div>
  );
};

const ParticipantPatrolEdit: FunctionComponent<{
  participant: Participant;
  year?: number;
  patrols: Data["patrols"];
}> = ({ participant, year: sourceYear, patrols }) => {
  const sourcePatrolInstance =
    sourceYear !== undefined ? patrols.byParticipantByYear[participant.key][sourceYear] : undefined;
  const sourcePatrolYear = sourcePatrolInstance?.years.find((p) => p.year == sourceYear);
  const sourcePatrol = sourcePatrolInstance?.key;
  const sourceRole = !sourcePatrolYear
    ? undefined
    : sourcePatrolYear.pl == participant.key
    ? "pl"
    : sourcePatrolYear.apl == participant.key
    ? "apl"
    : "member";

  const isNew = !sourcePatrolInstance;

  var [patrol, setPatrol] = useState(sourcePatrol);
  var [year, setYear] = useState(sourceYear);
  var [role, setRole] = useState<typeof sourceRole>(sourceRole);
  var [dirty, setDirty] = useState(false);

  const patrolChanged = useCallback((e: Event) => {
    setPatrol((e.target as HTMLSelectElement).value || undefined);
    setDirty(true);
  }, []);
  const yearChanged = useCallback((e: Event) => {
    var val = (e.target as HTMLInputElement).value;
    setYear(!val ? undefined : +val);
    setDirty(true);
  }, []);
  const roleChanged = useCallback((e: Event) => {
    setRole(((e.target as HTMLSelectElement).value || undefined) as typeof sourceRole | undefined);
    setDirty(true);
  }, []);

  const clear = useCallback((e: Event) => {
    e.preventDefault();
    setPatrol(undefined);
    setYear(undefined);
    setRole(undefined);
    setDirty(false);
  }, []);

  const add = useCallback(
    async function (e: Event) {
      e.preventDefault();
      const patch: ParticipantPatch = {
        type: "participant-patch",
        key: participant.key,
        patrols: [
          {
            year: year!,
            patrol: patrol!,
            role: role == "member" ? undefined : role,
          },
        ],
      };

      getEventer()!(patch);
    },
    [year, patrol, role, participant.key]
  );

  var dirty = patrol != sourcePatrol || year != sourcePatrolYear?.year || role != sourceRole;

  var valid = (patrol || "") in patrols.keyed && year && year > 2000 && year < 3000 && role;

  const years = useMemo(() => patrols.years.sort((a, b) => b - a), [patrols.years]);

  return (
    <form class="patrol-edit">
      <div class="form-row">
        <div class="form-group col-md-2">
          <select class="form-control" onChange={patrolChanged} value={patrol || ""}>
            {(isNew || !(patrol || "" in patrols.keyed)) && <option />}
            {patrols.keys
              .map((pKey) => patrols.keyed[pKey])
              .map((patrol) => (
                <option key={patrol?.key || ""} value={patrol?.key}>
                  {patrol?.name}
                </option>
              ))}
          </select>
        </div>
        <div class="form-group col-md-2">
          <input
            type="number"
            min={2000}
            max={3000}
            class="form-control"
            onChange={yearChanged}
            id={`years-for-${participant.key}-${year || ""}`}
            list={`years-for-${participant.key}`}
            value={year || ""}
          />
          <datalist id={`years-for-${participant.key}`}>
            {years.map((year) => (
              <option value={year} />
            ))}
          </datalist>
        </div>
        <div class="form-group col-md-2">
          <select class="form-control" onChange={roleChanged} value={role || ""}>
            {isNew && <option></option>}
            <option value="member">Lid</option>
            <option value="pl">PL</option>
            <option value="apl">APL</option>
          </select>
        </div>
        {!isNew ? (
          <Fragment>
            <div class="form-group col-md-2">
              <input
                type="submit"
                class="btn btn-primary col-md-12"
                value="Opslaan"
                onClick={() => setDirty(false)}
                disabled={!valid || !dirty}
              />
            </div>
            <div class="form-group col-md-2">
              <input type="submit" class="btn btn-danger col-md-12" value="Verwijderen" />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div class="form-group col-md-2">
              <input
                type="submit"
                class="btn btn-primary col-md-12"
                value="Toevoegen"
                onClick={add}
                disabled={!valid || !dirty}
              />
            </div>
            <div class="form-group col-md-2">
              <input
                type="submit"
                class="btn btn-danger col-md-12"
                value="Wissen"
                onClick={clear}
                disabled={!patrol && !year && !role}
              />
            </div>
          </Fragment>
        )}
      </div>
    </form>
  );
};

type FormProps<TType extends "checkbox" | "text"> = {
  id: string;
  type: string;
  onFocus?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  onChange?: (e: Event) => void;
} & (TType extends "checkbox" ? { type: "checkbox"; checked: boolean } : { type: "text"; value: string });
type Handler<TType extends "checkbox" | "text"> = {
  dirty: boolean;
  props: FormProps<TType>;
  value: TypeForType<TType>;
};
type TypeForType<T extends "checkbox" | "text"> = T extends "checkbox" ? boolean : string;

function useFormHandler(
  type: "text",
  field: string,
  sourceValue: string,
  onChange?: (value: string, field: string) => void
): Handler<"text">;
function useFormHandler(
  type: "checkbox",
  field: string,
  sourceValue: boolean,
  onChange?: (value: boolean, field: string) => void
): Handler<"checkbox">;
function useFormHandler<TType extends "checkbox" | "text">(
  type: TType,
  field: string,
  sourceValue: TypeForType<TType>,
  onChange?: (value: string & boolean, field: string) => void
): Handler<TType> {
  if (type == "checkbox") {
    return useFormHandlerCheckbox(
      field,
      sourceValue as TypeForType<"checkbox">,
      onChange as ((value: TypeForType<"checkbox">, field: string) => void) | undefined
    ) as any;
  } else {
    return useFormHandlerText(
      field,
      sourceValue as TypeForType<"text">,
      onChange as ((value: TypeForType<"text">, field: string) => void) | undefined
    ) as any;
  }
}
function useFormHandlerText(
  field: string,
  sourceValue: string,
  onChange?: (value: string, field: string) => void
): Handler<"text"> {
  const [dirty, setDirty] = useState(false);
  const [formValue, setFormValue] = useState(sourceValue);
  const [lastSourceValue, setLastSourceValue] = useState(sourceValue);
  const [valueBeforeFocus, setValueBeforeFocus] = useState("");

  const onChangeCallback = useCallback((e: Event) => {
    setFormValue((e.target as HTMLInputElement).value);
    setDirty(true);
  }, []);

  const onFocusCallback = useCallback(() => {
    setValueBeforeFocus(formValue);
  }, [formValue]);

  const onBlurCallback = useCallback(() => {
    if (formValue != valueBeforeFocus) {
      onChange && onChange(formValue, field);
    }
  }, [formValue, valueBeforeFocus]);

  useMemo(() => {
    if (formValue == lastSourceValue) {
      setFormValue(sourceValue);
    }
    if (sourceValue != lastSourceValue) {
      setLastSourceValue(sourceValue);
    }
    if (formValue == sourceValue) {
      setDirty(false);
    }
  }, [sourceValue, lastSourceValue, formValue]);

  const props: FormProps<"text"> = useMemo(
    () => ({
      type: "text",
      id: field,
      value: formValue || "",
      onChange: onChangeCallback,
      onFocus: onFocusCallback,
      onBlur: onBlurCallback,
    }),
    [formValue, field]
  );

  return useMemo(
    () => ({
      dirty,
      value: formValue,
      props,
    }),
    [props, formValue, dirty]
  );
}
function useFormHandlerCheckbox(
  field: string,
  sourceValue: boolean,
  onChange?: (value: boolean, field: string) => void
): Handler<"checkbox"> {
  const [dirty, setDirty] = useState(false);
  const [formValue, setFormValue] = useState(sourceValue);
  const [lastSourceValue, setLastSourceValue] = useState(sourceValue);

  const onChangeCallback = useCallback((e: Event) => {
    setFormValue((e.target as HTMLInputElement).checked);
    setDirty(true);
    onChange && onChange((e.target as HTMLInputElement).checked, field);
  }, []);

  useMemo(() => {
    if (formValue == lastSourceValue) {
      setDirty(false);
      setFormValue(sourceValue);
    }
    if (sourceValue != lastSourceValue) {
      setLastSourceValue(sourceValue);
    }
    if (formValue == sourceValue) {
      setDirty(false);
    }
  }, [sourceValue, lastSourceValue, formValue]);

  const props: FormProps<"checkbox"> = useMemo(
    () => ({
      type: "checkbox",
      id: field,
      checked: formValue,
      onChange: onChangeCallback,
    }),
    [formValue, field]
  );

  return useMemo(
    () => ({
      dirty,
      value: formValue,
      props,
    }),
    [props, formValue, dirty]
  );
}

export const ParticipantEdit: FunctionalComponent<{ participant: string }> = ({ participant }) => {
  var data = useStore((s) => s.data);

  if (!data || !data.participants.keys.length) {
    return <div>Loading...</div>;
  }
  if (!(participant in data.participants.keyed)) {
    return <Redirect href="/" />;
  }
  return <ParticipantEditImpl participant={participant} data={data} />;
};
