import { h, FunctionalComponent } from "preact";
import { Flag } from "../../shared";
import { useCallback } from "preact/hooks";
import { TeamFlagStats } from "./TeamFlagStats";

export const DashboardTeamCapture: FunctionalComponent<{
  flag: TeamFlagStats;
  focusFlag: (flag: Flag) => void;
}> = ({ flag, focusFlag }) => {
  const handleClick = useCallback(
    (e: Event) => {
      e.stopPropagation();
      focusFlag(flag.flag);
    },
    [flag, focusFlag]
  );

  return (
    <div class="capture" onClick={handleClick}>
      <span class="capture-name">{flag.flag.name}</span>
      <span class="capture-points">{flag.flag.points} 💰</span>
    </div>
  );
};
