import { FunctionalComponent, Fragment, h } from "preact";
import { render as renderToString } from "preact-render-to-string";
import { useEffect, useState } from "preact/hooks";

const moduleParser = new DOMParser();

export const Title: FunctionalComponent = ({ children }) => {
  const [title, setTitle] = useState("");
  useEffect(() => {
    (async () => {
      await new Promise((a) => setTimeout(a));
      const renderedString = renderToString(<span>{children}</span>);
      const element = moduleParser.parseFromString(renderedString, "text/html");
      setTitle(element.body.textContent!);
    })();
  }, [children]);
  useEffect(() => {
    const current = document.title;
    document.title = title;
    return () => (document.title = current);
  }, [title]);
  return <Fragment />;
};
