import { Participant, Data, Level, Category, Skill, Progress } from "./types";
import { map, groupBy, keyBy } from "../functional";

export function transformParticipantDetails(
  participant: Participant,
  progress: Data["progress"],
  categories: Data["categories"],
  skills: Data["skills"],
  levels: Data["levels"]
): ParticipantDetails {
  const skillsForParticipant = progress.byParticipantThenSkill[participant.key] || {};

  const byCategory: Record<string, CategoryDetails> = {};
  for (const categoryKey of categories.keys) {
    const categorySkills = skills.byCategory[categoryKey];
    const detailedCategorySkills = categorySkills.map<SkillDetails>((s) => {
      const progress = skillsForParticipant[s.key] || {};
      return {
        skill: s,
        progressRank: ProgressRank[progress.done ? "done" : progress.doing ? "doing" : "todo"],
        doing: progress.doing,
        done: progress.done,
      };
    });

    const byLevel: LevelDetails[] = [];
    for (const level of levels.byRank) {
      const detailedLevelSkills = detailedCategorySkills.filter(({ skill }) => skill.level == level.key);

      byLevel[level.rank] = {
        level,
        progressRank: detailedLevelSkills.length
          ? Math.min(...detailedLevelSkills.map((s) => s.progressRank))
          : undefined,
        skills: detailedLevelSkills,
        byProgress: {
          todo: detailedLevelSkills.filter((s) => s.progressRank == ProgressRank.todo),
          doing: detailedLevelSkills.filter((s) => s.progressRank == ProgressRank.doing),
          done: detailedLevelSkills.filter((s) => s.progressRank == ProgressRank.done),
        },
        filtered: detailedLevelSkills.length
          ? {
              level,
              progressRank: Math.min(...detailedLevelSkills.map((s) => s.progressRank)),
              skills: detailedLevelSkills,
              byProgress: {
                todo: detailedLevelSkills.filter((s) => s.progressRank == ProgressRank.todo),
                doing: detailedLevelSkills.filter((s) => s.progressRank == ProgressRank.doing),
                done: detailedLevelSkills.filter((s) => s.progressRank == ProgressRank.done),
              },
            }
          : undefined,
      };
    }

    const byLevelWithProgress = byLevel.map((l) => l.filtered).filter((l) => l) as FilteredLevelDetails[];

    const firstUnfinishedLevel = byLevelWithProgress.find((l) => l.progressRank != ProgressRank.done);
    byCategory[categoryKey] = {
      attainedLevel: firstUnfinishedLevel
        ? levels.byRank[firstUnfinishedLevel.level.rank - 1]
        : // if all finished, find highest ranked skill available
          levels.byRank[Math.max(...skills.byCategory[categoryKey].map((s) => levels.keyed[s.level].rank))],
      progressRank: byLevelWithProgress.length
        ? Math.min(...byLevelWithProgress.map((l) => l.progressRank!))
        : undefined,
      byLevel,
      category: categories.keyed[categoryKey],
      filtered: byLevelWithProgress.length
        ? {
            attainedLevel: firstUnfinishedLevel
              ? levels.byRank[firstUnfinishedLevel.level.rank - 1]
              : // if all finished, find highest ranked skill available
                levels.byRank[Math.max(...skills.byCategory[categoryKey].map((s) => levels.keyed[s.level].rank))],
            progressRank: Math.min(...byLevelWithProgress.map((l) => l.progressRank)),
            byLevel: byLevelWithProgress,
            category: categories.keyed[categoryKey],
          }
        : undefined,
    };
  }

  const categoriesWithProgress = Object.values(byCategory)
    .map((c) => c.filtered)
    .filter((c) => c) as FilteredCategoryDetails[];

  const rankPerCategory = Object.values(byCategory).map((c) => c.attainedLevel.rank);
  return {
    participant,
    attainedLevel: levels.byRank[rankPerCategory.length ? Math.min(...rankPerCategory) : 0],
    progressRank: categoriesWithProgress.length
      ? Math.min(...categoriesWithProgress.map((c) => c.progressRank!))
      : undefined,
    byCategory,
    filtered: categoriesWithProgress.length
      ? {
          participant,
          attainedLevel: levels.byRank[rankPerCategory.length ? Math.min(...rankPerCategory) : 0],
          progressRank: Math.min(...categoriesWithProgress.map((c) => c.progressRank)),
          byCategory: keyBy(categoriesWithProgress, (c) => c.category.key),
        }
      : undefined,
  };
}

export enum ProgressRank {
  todo = 0,
  doing = 1,
  done = 2,
}
export const RankProgress: Record<ProgressRank, ProgressString> = {
  0: "todo",
  1: "doing",
  2: "done",
};

export type ProgressString = "todo" | "doing" | "done";
export type ParticipantDetails = UnfilteredParticipantDetails;
export type CategoryDetails = UnfilteredCategoryDetails;
export type LevelDetails = UnfilteredLevelDetails;

export interface SkillDetails {
  skill: Skill;
  doing: Progress | undefined;
  done: Progress | undefined;
  progressRank: ProgressRank;
}

export interface UnfilteredParticipantDetails {
  participant: Participant;
  attainedLevel: Level;
  progressRank?: ProgressRank;
  byCategory: Record<string, UnfilteredCategoryDetails>;
  filtered?: FilteredParticipantDetails;
}
export interface UnfilteredCategoryDetails {
  attainedLevel: Level;
  progressRank?: ProgressRank;
  category: Category;
  byLevel: UnfilteredLevelDetails[];
  filtered?: FilteredCategoryDetails;
}
export interface UnfilteredLevelDetails {
  level: Level;
  progressRank?: ProgressRank;
  skills: SkillDetails[];
  byProgress: Record<ProgressString, SkillDetails[]>;
  filtered?: FilteredLevelDetails;
}

export interface FilteredParticipantDetails {
  participant: Participant;
  attainedLevel: Level;
  progressRank: ProgressRank;
  byCategory: Record<string, FilteredCategoryDetails>;
}
export interface FilteredCategoryDetails {
  attainedLevel: Level;
  progressRank: ProgressRank;
  category: Category;
  byLevel: FilteredLevelDetails[];
}
export interface FilteredLevelDetails {
  level: Level;
  progressRank: ProgressRank;
  skills: SkillDetails[];
  byProgress: Record<ProgressString, SkillDetails[]>;
}

export function transformParticipantOverview(participantDetails: ParticipantDetails): ParticipantOverview {
  return {
    participant: participantDetails.participant,
    categories: Object.values(participantDetails.byCategory)
      .map<CategoryOverview>((c) => ({
        category: c.category,
        attainedLevel: c.attainedLevel,
        nextLevel: c.byLevel[c.attainedLevel.rank + 1],
      }))
      .sort((a, b) => a.attainedLevel.rank - b.attainedLevel.rank),
    attainedLevel: participantDetails.attainedLevel,
    details: participantDetails,
  };
}

export interface ParticipantOverview {
  participant: Participant;
  attainedLevel: Level;
  categories: CategoryOverview[];
  details: ParticipantDetails;
}
export interface CategoryOverview {
  category: Category;
  attainedLevel: Level;
  nextLevel: LevelDetails | undefined;
}

export function transformMostRecentPatrolYear(years: Data["patrols"]["years"]) {
  return Math.max(...years);
}
