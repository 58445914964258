import { h, FunctionalComponent } from "preact";
import { Data } from "../store/types";
import { CaptureEditor } from "./CaptureEditor";

export const CapturesEditor: FunctionalComponent<{ map: Data["map"] }> = ({ map }) => {
  return (
    <div>
      {map.captures.keys
        .map((captureKey) => map.captures.keyed[captureKey]!)
        .map((capture) => (
          <div key={capture.key} style="overflow-anchor: none">
            <CaptureEditor map={map} capture={capture} />
          </div>
        ))}
      <div key="empty" style="overflow-anchor: auto">
        <CaptureEditor map={map} />
      </div>
    </div>
  );
};
