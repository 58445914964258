import { FunctionalComponent, Fragment, h, VNode, FunctionComponent } from "preact";
import { Data, Level } from "./store/types";
import { map } from "./functional";

export enum BadgeType {
  secondary,
  primary,
  muted,
  colorized,
}
export type BadgeProps = {
  level: Level;
  type?: BadgeType;
  emphasize?: boolean;
  colorize?: boolean;
  prefix?: VNode;
  postfix?: VNode;
  stars?: boolean;
};

// https://www.flaticon.com/packs/rating-and-vadilation-set
export const stars = {
  fullYellow:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA1MDEuMjggNTAxLjI4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDEuMjggNTAxLjI4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2Z" +
    "SIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8cG9seWdvbiBzdHlsZT0iZmlsbDojRkZDRDAwOyIgcG9pbnRzPSI1MDEuMjgsMTk0LjM3IDMzNS4yN" +
    "iwxNTkuMzMgMjUwLjY0LDEyLjI3IDI1MC42NCw0MTkuNzcgNDA1LjU0LDQ4OS4wMSAzODcuNTYsMzIwLjI5ICAiLz4KCTxwb2x5Z29uIHN0eWxlPSJmaWxsOiNGRkRBN" +
    "DQ7IiBwb2ludHM9IjE2Ni4wMiwxNTkuMzMgMCwxOTQuMzcgMTEzLjcyLDMyMC4yOSA5NS43NCw0ODkuMDEgMjUwLjY0LDQxOS43NyAyNTAuNjQsMTIuMjcgICIvPgo8L" +
    "2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnP" +
    "go8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
  fullBlack:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA0ODIuMjA3IDQ4Mi4yMDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4Mi4yMDcgNDgyLjIwNzsiIHhtbDpzcGFjZT0icHJlc" +
    "2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+Cjxwb2x5Z29uIHBvaW50cz0iNDgyLjIwNywxODYuOTczIDMyMi41MDgsMTUzLjI2OSAyNDEuMTA0LDExL" +
    "jgwMyAxNTkuNjk5LDE1My4yNjkgMCwxODYuOTczIDEwOS4zODgsMzA4LjEwOCA5Mi4wOTQsNDcwLjQwNCAgIDI0MS4xMDQsNDAzLjgwMyAzOTAuMTEzLDQ3MC40MDQgM" +
    "zcyLjgxOCwzMDguMTA4ICIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nP" +
    "go8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K",
  halfYellow:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA1MDEuMjggNTAxLjI4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDEuMjggNTAxLjI4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2Z" +
    "SIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8cG9seWdvbiBzdHlsZT0iZmlsbDojOUJDOUZGOyIgcG9pbnRzPSI1MDEuMjgsMTk0LjM3IDMzNS4yN" +
    "iwxNTkuMzMgMjUwLjY0LDEyLjI3IDI1MC42NCw0MTkuNzcgNDA1LjU0LDQ4OS4wMSAzODcuNTYsMzIwLjI5ICAiLz4KCTxwb2x5Z29uIHN0eWxlPSJmaWxsOiNGRkRBN" +
    "DQ7IiBwb2ludHM9IjE2Ni4wMiwxNTkuMzMgMCwxOTQuMzcgMTEzLjcyLDMyMC4yOSA5NS43NCw0ODkuMDEgMjUwLjY0LDQxOS43NyAyNTAuNjQsMTIuMjcgICIvPgo8L" +
    "2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnP" +
    "go8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
  halfBlack:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA0ODIuMjA3IDQ4Mi4yMDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4Mi4yMDcgNDgyLjIwNzsiIHhtbDpzcGFjZT0icHJlc" +
    "2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxwYXRoIGQ9Ik00ODIuMjA3LDE4Ni45NzNsLTE1OS42OTktMzMuNzA1TDI0MS4xMDQsMTEuODAzbC04M" +
    "S40MDQsMTQxLjQ2NUwwLDE4Ni45NzNsMTA5LjM4OCwxMjEuMTM0TDkyLjA5NCw0NzAuNDA0bDE0OS4wMS02Ni42ICBsMTQ5LjAxLDY2LjZsLTE3LjI5NC0xNjIuMjk2T" +
    "DQ4Mi4yMDcsMTg2Ljk3M3ogTTI0MS4xMDQsMzcwLjk0M1Y3MS45NTNsNjIuMDksMTA3LjlMNDI1LDIwNS41NjFsLTgzLjQzMyw5Mi4zOTNsMTMuMTkxLDEyMy43ODggI" +
    "EwyNDEuMTA0LDM3MC45NDN6IiBmaWxsPSIjMDAwMDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L" +
    "2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
  emptyYellow:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA1MDEuMjggNTAxLjI4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDEuMjggNTAxLjI4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2Z" +
    "SIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8cG9seWdvbiBzdHlsZT0iZmlsbDojOUJDOUZGOyIgcG9pbnRzPSI1MDEuMjgsMTk0LjM3IDMzNS4yN" +
    "iwxNTkuMzMgMjUwLjY0LDEyLjI3IDI1MC42NCw0MTkuNzcgNDA1LjU0LDQ4OS4wMSAzODcuNTYsMzIwLjI5ICAiLz4KCTxwb2x5Z29uIHN0eWxlPSJmaWxsOiNCRERCR" +
    "kY7IiBwb2ludHM9IjE2Ni4wMiwxNTkuMzMgMCwxOTQuMzcgMTEzLjcyLDMyMC4yOSA5NS43NCw0ODkuMDEgMjUwLjY0LDQxOS43NyAyNTAuNjQsMTIuMjcgICIvPgo8L" +
    "2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnP" +
    "go8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
  emptyBlack:
    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0c" +
    "mF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnL" +
    "zIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiI" +
    "HZpZXdCb3g9IjAgMCA0ODIuMjA3IDQ4Mi4yMDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4Mi4yMDcgNDgyLjIwNzsiIHhtbDpzcGFjZT0icHJlc" +
    "2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxwYXRoIGQ9Ik00ODIuMjA3LDE4Ni45NzNsLTE1OS42OTktMzMuNzA1TDI0MS4xMDQsMTEuODAzbC04M" +
    "S40MDQsMTQxLjQ2NUwwLDE4Ni45NzNsMTA5LjM4OCwxMjEuMTM0TDkyLjA5NCw0NzAuNDA0bDE0OS4wMS02Ni42ICBsMTQ5LjAxLDY2LjZsLTE3LjI5NC0xNjIuMjk2T" +
    "DQ4Mi4yMDcsMTg2Ljk3M3ogTTI0MS4xMDQsMzcwLjk0M2wtMTEzLjY1NCw1MC43OThsMTMuMTkxLTEyMy43ODhsLTgzLjQzMy05Mi4zOTNsMTIxLjgwNy0yNS43MDcgI" +
    "Gw2Mi4wOS0xMDcuOWw2Mi4wOSwxMDcuOUw0MjUsMjA1LjU2MWwtODMuNDMzLDkyLjM5M2wxMy4xOTEsMTIzLjc4OEwyNDEuMTA0LDM3MC45NDN6IiBmaWxsPSIjMDAwM" +
    "DAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+C" +
    "jxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
};

function cacheSvg(uri: string) {
  var canvas = document.createElement("canvas");
  canvas.width = 8;
  canvas.height = 8;
  var ctx = canvas.getContext("2d")!;
  var img = new Image();
  img.onload = () => {
    ctx.drawImage(img, 0, 0, 8, 8);
    uri = canvas.toDataURL();
  };
  img.src = uri;

  return () => uri;
}

var cachedSvg = map(stars).to(cacheSvg);

const starImages_ = map(cachedSvg).to<FunctionComponent>((src) => () => <img src={src()} style="width: 0.5em" />);
export const starImages = map(starImages_).to((Img) => <Img />);

export const yStarD = <img src={stars.fullYellow} style="width: 0.5em" />;
export const bStarD = <img src={stars.emptyYellow} style="width: 0.5em;opacity:0.5" />;
export const Badge: FunctionalComponent<BadgeProps> = ({
  level,
  type,
  emphasize,
  colorize,
  prefix,
  postfix,
  stars,
}) => {
  if (emphasize !== undefined) type = emphasize ? BadgeType.primary : BadgeType.muted;
  if (colorize) type = BadgeType.colorized;
  return (
    <small
      class={`me-1 badge bg-${
        type == BadgeType.primary
          ? "primary"
          : type == BadgeType.muted
          ? "light text-muted"
          : type == BadgeType.colorized
          ? "colorize"
          : "secondary"
      } badge-rank-${level.rank}`}
    >
      {prefix}
      {level.name}
      {postfix}
      <br />
      {stars && <StarLine completion={level.rank} stars={5} />}
    </small>
  );
};

export const StarLine: FunctionComponent<{
  stars: number;
  completion: number;
}> = ({ stars, completion }) => {
  return <Fragment>{[...new Array(stars)].map((_, i) => (i <= completion ? yStarD : bStarD))}</Fragment>;
};

export const BadgeBar: FunctionalComponent<{
  levels: Data["levels"];
  attainedLevelKey: string;
}> = ({ levels, attainedLevelKey }) => {
  return (
    <Fragment>
      {levels.byRank.map((level) => (
        <Badge level={level} type={level.key == attainedLevelKey ? BadgeType.primary : BadgeType.muted} />
      ))}
    </Fragment>
  );
};
