import { Token, store, State } from "./store";
import produce from "immer";
import { Claims } from "../../shared";

export function login(token: Token, claims: Claims) {
  store.update(
    produce((s: State) => {
      s.session.token = token;
      s.session.claims = {
        permissions: claims.permissions.reduce(
          (perms, perm) => ((perms[perm] = true), perms),
          {} as Record<string, boolean>
        ),
      };
    })
  );
  handleTokenExpiration(token);
}

export function handleTokenExpiration(token: Token | undefined) {
  if (token === undefined) {
    return;
  }
  setTimeout(() => {
    if ((store.getState().session, token == token)) {
      store.update(
        produce((s: State) => {
          s.session.token = undefined;
          s.session.claims = { permissions: {} };
        })
      );
    }
  }, Math.max(0, +token.expiration - Date.now()));
}

export function logout() {
  store.update(
    produce((s: State) => {
      s.session.token = undefined;
      s.session.claims = { permissions: {} };
    })
  );
}
