import { h, FunctionalComponent, Fragment } from "preact";
import { useState, useCallback } from "preact/hooks";
import { useStore } from "../store/store";
import { Card } from "./Card";
import { CapturesEditor } from "./CapturesEditor";
import { FlagsEditor } from "./FlagsEditor";
import { TeamsEditor } from "./TeamsEditor";
import { Icon } from "./Icon";

export const Editor: FunctionalComponent<{
  markerCoords: google.maps.LatLngLiteral;
}> = ({ markerCoords }) => {
  const map = useStore((s) => s.data?.map);
  const [tab, setTab] = useState<undefined | "captures" | "teams" | "flags">(undefined);
  const openTab = useCallback(
    (next: typeof tab) => {
      if (next === tab) {
        if (!next) {
          return;
        }

        next = undefined;
      }

      setTab(next);
    },
    [tab]
  );
  return (
    <Card>
      <ul class="editor-tabs" style="display: flex; padding: 0; margin: 0">
        <li class={tab === "captures" ? "selected" : ""} onClick={() => openTab("captures")}>
          Overnames
        </li>
        <li class={tab === "teams" ? "selected" : ""} onClick={() => openTab("teams")}>
          Teams
        </li>
        <li class={tab === "flags" ? "selected" : ""} onClick={() => openTab("flags")}>
          Vlaggen
        </li>
        {tab !== undefined && (
          <Fragment>
            <div style="flex: 1" />
            <li style={{}} onClick={() => openTab(undefined)}>
              <Icon icon="x-circle-fill" />
            </li>
          </Fragment>
        )}
      </ul>
      {map && (
        <div class="editor">
          {tab === "captures" && <CapturesEditor map={map} />}
          {tab === "teams" && <TeamsEditor map={map} />}
          {tab === "flags" && <FlagsEditor map={map} markerCoords={markerCoords} />}
        </div>
      )}
    </Card>
  );
};
