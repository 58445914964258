import { createSubscribers } from "../shared/subscribers";
import { C2S, S2C } from "../shared/eventer";
import { Dictionary } from "./store/types";
import SockJs from "sockjs-client";
import { boolOptions } from "yaml/types";

type Lazy<T> = undefined | T;
export type EventerPipe<TEvent, TSnapshot extends object> = {
  setToken(token: string | undefined, refresh: boolean): void;
  sendEvent(event: TEvent, eventId: number): void;
  sendSnapshotRequest(): void;
  onEvent(listener: (message: TEvent, eventId: number, ownEvent: boolean) => void): () => void;
  onSnapshot(listener: (message: TSnapshot) => void): () => void;
  onConnect(listener: () => void): () => void;
  onConnectStatus(listener: (connected: boolean) => void): () => void;
};
export type EventerClient<TEvent, TState> = {
  queue(event: TEvent): void;
  addStateChangeListener(listener: (state: TState) => void): () => void;
};
export function createEventerClient<TEvent, TState extends object, TSnapshot extends object>(
  init: (init: TSnapshot) => TState,
  apply: (state: TState, event: TEvent) => TState,
  pipe: EventerPipe<TEvent, TSnapshot>
): EventerClient<TEvent, TState> {
  const listeners = createSubscribers<(state: TState) => void>();
  const eventQueue: { event: TEvent; eventId: number }[] = [];

  let eventId = 0;

  let state: Lazy<TState>;
  let confirmedState: Lazy<TState>;

  pipe.onEvent((event, eventId, ownEvent) => {
    if (confirmedState === undefined) {
      console.error(`event send by server before snapshot`, event);
      return;
    }

    if (ownEvent) {
      if (eventQueue.length == 0) {
        console.error(`message send by server not expected, received ${eventId} but non queued locally`, event);
      } else if (eventQueue[0].eventId != eventId) {
        console.error(
          `message id send by server not expected, received ${eventId} instead of local ${eventQueue[0].eventId}`,
          event
        );
        eventQueue.length = 0;
        pipe.sendSnapshotRequest();
      }
      eventQueue.shift();
    }

    state = confirmedState = apply(confirmedState, event);
    state = eventQueue.reduce((s, e) => apply(s, e.event), confirmedState);
    listeners.invoke(state);
  });
  pipe.onSnapshot((snapshot) => {
    state = confirmedState = init(snapshot);
    state = eventQueue.reduce((s, e) => apply(s, e.event), confirmedState);
    listeners.invoke(state);
  });
  pipe.onConnect(async () => {
    await new Promise((accept) => setTimeout(accept, 10));
    pipe.sendSnapshotRequest();
    eventQueue.forEach((e) => pipe.sendEvent(e.event, e.eventId));
  });

  try {
    pipe.sendSnapshotRequest();
  } catch {
    console.debug("Initial snapshot request failed");
  }

  return {
    queue(event: TEvent) {
      eventQueue.push({
        eventId: ++eventId,
        event,
      });
      pipe.sendEvent(event, eventId);

      if (state !== undefined) {
        state = apply(state, event);
        listeners.invoke(state);
      }
    },
    addStateChangeListener: listeners.addSubscriber,
  };
}

export async function createEventerWebSocketPipe<TEvent, TSnapshot extends object>(
  endpoint: string,
  type: "ws" | "sockjs" = "ws"
): Promise<EventerPipe<TEvent, TSnapshot>> {
  let handlers = {
    event: createSubscribers<(message: TEvent, eventId: number, ownEvent: boolean) => void>(),
    snapshot: createSubscribers<(event: TSnapshot) => void>(),
    connect: createSubscribers<() => void>(),
    connectStatus: createSubscribers<(connected: boolean) => void>(),
  };

  let senderIds: Dictionary<number, boolean> = {};

  let nextExpiry = +new Date() + 1000;
  function restartPingWaiter(pingInterval: number) {
    nextExpiry = +new Date() + pingInterval * 2;
  }

  async function createSocket() {
    let socket = type === "sockjs" ? new SockJs(endpoint) : new WebSocket(endpoint);

    socket.onmessage = ({ data }) => {
      const message: S2C.Message<TEvent, TSnapshot> = JSON.parse(data);
      if (message.type == "snapshot") {
        handlers.snapshot.invoke(message.snapshot);
      } else if (message.type == "event") {
        handlers.event.invoke(message.event, message.eventId, senderIds[message.senderId] == true);
      } else if (message.type == "ping") {
        if ((window as any).missPing) {
          return;
        }
        restartPingWaiter(message.pingInterval);
      } else if (message.type == "init") {
        senderIds[message.senderId] = true;
        restartPingWaiter(message.pingInterval);
      }
    };

    try {
      await new Promise((accept, reject) => {
        socket.onopen = accept;
        // long delay, sockjs can take a while
        setTimeout(() => reject("connecting took too long"), 10000);
      });
    } catch (e) {
      socket.onopen = null;
      socket.onmessage = null;
      throw e;
    }

    return socket;
  }

  async function ensureCreateSocket() {
    while (true) {
      try {
        console.debug("Connecting...");
        return await createSocket();
      } catch (e) {
        console.warn("Failed to create new socket, retrying", e);
        await new Promise((accept) => setTimeout(accept, 1000));
      }
    }
  }

  let socket = await new Promise<WebSocket>(async (accept) => {
    accept(ensureCreateSocket());
    restartPingWaiter(5000);

    while (true) {
      const initialWait = nextExpiry - +new Date();
      console.debug("Waiting...", initialWait);
      await new Promise((accept) => setTimeout(accept, initialWait));

      const current = +new Date();
      if (nextExpiry > current) {
        // Something pushed forward the expiry, connection not yet expired, restart loop
        continue;
      }

      // In case of e.g. a breakpoint, when triggering a reset, wait another few ms to give the network time to catch up.
      console.warn("Almost reconnecting");
      await new Promise((accept) => setTimeout(accept, 10));

      handlers.connectStatus.invoke(false);

      // Connection expired, reconnect
      console.warn("Reconnecting");
      try {
        socket.onopen = null;
        socket.onmessage = null;
        socket.close();
      } catch (e) {
        console.error("Failed to cleanly disconnect when resetting socket", e);
      }

      socket = await ensureCreateSocket();
      handlers.connect.invoke();
      handlers.connectStatus.invoke(true);
    }
  });

  let currentToken: string | undefined = undefined;

  function sendSnapshotRequest() {
    const message: C2S.Message<TEvent> = {
      type: "snapshotRequest",
      token: currentToken,
    };
    socket.send(JSON.stringify(message));
  }

  return {
    setToken(token: string | undefined, refresh: boolean) {
      if (token != currentToken) {
        currentToken = token;
        sendSnapshotRequest();
      }
    },
    sendEvent(event, eventId) {
      const message: C2S.Message<TEvent> = {
        type: "event",
        event,
        eventId,
        token: currentToken,
      };
      socket.send(JSON.stringify(message));
    },
    sendSnapshotRequest,
    onSnapshot: handlers.snapshot.addSubscriber,
    onEvent: handlers.event.addSubscriber,
    onConnect: handlers.connect.addSubscriber,
    onConnectStatus: handlers.connectStatus.addSubscriber,
  };
}
