import { h, FunctionalComponent, toChildArray, JSX } from "preact";

export const Card: FunctionalComponent<JSX.HTMLAttributes & { direction?: "horizontal" | "vertical" }> = ({
  children,
  direction,
  class: clazz,
  ...props
}) => {
  return (
    <div {...props} class={`map-panel ${direction} ${clazz ?? ""}`}>
      {toChildArray(children)}
    </div>
  );
};
