import { FunctionalComponent, h, JSX } from "preact";

interface ContainerProps {
  fluid?: boolean;
}
export const Container: FunctionalComponent<ContainerProps & JSX.HTMLAttributes> = ({
  children,
  fluid,
  class: clazz,
  ...rest
}) => (
  <div {...rest} class={`${fluid ? "container-fluid" : "container"} ${clazz ?? ""}`}>
    {children}
  </div>
);

export const Row: FunctionalComponent<JSX.HTMLAttributes> = ({ children, ...rest }) => (
  <div {...rest} class="row">
    {children}
  </div>
);

export const Col: FunctionalComponent<JSX.HTMLAttributes> = ({ children, ...rest }) => (
  <div {...rest} class="col">
    {children}
  </div>
);
