import { h, FunctionalComponent, Fragment } from "preact";
import { useStore } from "../store/store";
import { Flag } from "../../shared";
import { Card } from "./Card";
import { DashboardImpl } from "./DashboardImpl";

export const Dashboard: FunctionalComponent<{
  focusFlag: (flag: Flag) => void;
}> = ({ focusFlag }) => {
  const map = useStore((s) => s.data?.map);
  return !map ? <Fragment /> : <DashboardImpl map={map} focusFlag={focusFlag} />;
};
