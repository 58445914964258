import { h, FunctionalComponent } from "preact";
import { Flag, FlagCapture, FlagTeam } from "../../shared";
import { Data } from "../store/types";
import { useStore } from "../store/store";
import { useCallback, useMemo } from "preact/hooks";
import { getEventer } from "../singleton";
import { v4 } from "uuid";
import { safeColor } from "./utils";

const TeamHandle: FunctionalComponent<{
  team: FlagTeam;
  assignTeam: (team: FlagTeam) => void;
}> = ({ team, assignTeam }) => {
  const color = safeColor(team.color).darken(0.3).string();

  const colors = useMemo(() => {
    return [
      safeColor(team.color).darken(0.5).string(),
      safeColor(team.color).darken(0.5).alpha(0.6).string(),
      safeColor(team.color).lighten(0.8).string(),
    ];
  }, [team.color]);

  return (
    <div
      onClick={() => assignTeam(team)}
      style={{ color: colors[0], backgroundColor: colors[2] }}
      class="marker-info-team-assign"
    >
      <span class="team-name">{team.name}</span> <span class="team-symbol">{team.symbol}</span>
    </div>
  );
};

export const MarkerInfoImpl: FunctionalComponent<{
  teams: Data["map"]["teams"];
  flag: Flag;
  team?: FlagTeam;
  capture?: FlagCapture;
}> = ({ teams, flag, team }) => {
  const hasWrite = useStore((s) => !!s.session.claims.permissions.write);
  const assignTeam = useCallback(
    (team: FlagTeam) => {
      getEventer(true)({
        type: "flag-capture-upsert",
        capture: {
          key: v4(),
          flagKey: flag.key,
          teamKey: team.key,
          when: new Date().toISOString(),
        },
      });
    },
    [flag]
  );
  return (
    <div class="marker-info">
      <span style="font-weight: bold">
        {flag.points} 💰 Vlag {flag.name} bezit door {team?.name ?? "niemand"}
      </span>
      {flag.description && <p>{flag.description}</p>}
      {hasWrite && (
        <div>
          <hr />
          Neem over:
          {teams.keys
            .map((t) => teams.keyed[t]!)
            .map((t) => (
              <TeamHandle team={t} assignTeam={assignTeam} />
            ))}
        </div>
      )}
    </div>
  );
};
