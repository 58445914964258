// if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
//     require("preact/debug");
// }

import "core-js/stable";
import "regenerator-runtime/runtime";

import { h, render } from "preact";
import { App } from "./App";
import { store, Provider } from "./store/store";
import "./style.scss";
import "./bootstrap.scss";
import { createApi } from "./api";
import { Router } from "wouter-preact";
import useLocation from "wouter-preact/use-location";
import { setApi, setEventer } from "./singleton";
import { createBoundEventer } from "./storeEventer";
import { load as loadMaps } from "./maps-api";

const app = document.getElementById("app");

const useDecodedLocation: typeof useLocation = () => {
  const [loc, setLoc] = useLocation();
  return [decodeURIComponent(loc), setLoc];
};

render(
  <Provider>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol id="squares" viewBox="0 0 20 20">
        <rect height="10" width="10" y="0" x="0" fill="var(--patrol-color1)" />
        <rect height="10" width="10" y="0" x="10" fill="var(--patrol-color2)" />
        <rect height="10" width="10" y="10" x="0" fill="var(--patrol-color2)" />
        <rect height="10" width="10" y="10" x="10" fill="var(--patrol-color1)" />
      </symbol>
    </svg>
    <Router hook={useDecodedLocation}>
      <App />
    </Router>
  </Provider>,
  app!
);

(async () => {
  interface Config {
    host: string;
    debug: boolean;
    mapsKey?: string;
    "menu-logo": string;
    strings: {
      [key: string]: string;
    };
    features: {
      [key: string]: boolean;
    };
    flagHunt?: {
      bounds: google.maps.LatLngLiteral[];
    };
  }

  var config: Config = {
    debug: false,
    host: location.origin,
    strings: {},
    features: {},
    "menu-logo": "menu-logo.svg",
  };
  try {
    const configRequest = await fetch(`${location.origin}/config.json`);

    if (configRequest.status == 200) {
      config = { ...config, ...(await configRequest.json()) };
      config.host = config.host.replace("{host}", location.hostname);
    }
  } catch (error) {
    console.debug("loading config failed", error);
  }

  const link = document.createElement("link");
  link.rel = "stylesheet";
  (link.type = "text/css"), (link.href = `${config.host}/patrol-colors.css`);
  document.head.append(link);

  if (config.mapsKey) {
    loadMaps(config.mapsKey);
  }

  if (config.debug) {
    store.subscribe(console.debug);
    store.update((s) => ({ ...s, debug: true }));
  }

  store.update((s) => ({
    ...s,
    "menu-logo": config["menu-logo"],
    strings: config.strings,
    features: { ...s.features, ...config.features },
    flagHunt: config.flagHunt,
  }));

  setApi(createApi(config.host));
  setEventer(await createBoundEventer(config.host, (connected) => store.update((s) => ({ ...s, connected }))));
  console.log("loading config succeeded");
})();
